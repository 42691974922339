import { pageBackgroundClassname } from 'theme.cjs'
import twConfig from 'tailwind-light-config'
const { colors } = twConfig

const tailwindColors = colors

/**
 * Takes optional backgroundColor property and returns query
 * that tells contentful Images API to return image with that
 * background
 * @param backgroundColor string (must be a hex value withtou '#')
 * @returns string
 */
export const getContentfulImageBackgroundQuery = (backgroundColor: string): string => {
    if (backgroundColor) {
        return `bg=rgb:${backgroundColor?.replace('#', '')}`
    }
}

// returns contentful image background query with page background
export const getContentfulTransparentBackgroundImageQuery = (): string => {
    // we're using bg-gray-lighter as page background
    return getContentfulImageBackgroundQueryFromTailwindClass(pageBackgroundClassname)
}

/**
 * Takes tailwind bg-color class and returns contentful image bg query
 * with that color
 * @param tailwindClass string - background color tailwind class
 * @returns string
 */
export const getContentfulImageBackgroundQueryFromTailwindClass = (tailwindClass: string): string => {
    const splitTailwindClass = tailwindClass?.split('-')

    // currently only supports bg-color classes
    if (splitTailwindClass?.[0] !== 'bg') {
        return ''
    }

    if (splitTailwindClass?.[1] === 'transparent') {
        return getContentfulTransparentBackgroundImageQuery()
    }

    let backgroundColor: string

    // valid tailwind color class will have at least 2 length
    if (splitTailwindClass?.length === 2) {
        backgroundColor = tailwindColors[splitTailwindClass[1]]?.DEFAULT
    } else if (splitTailwindClass?.length === 3) {
        backgroundColor = tailwindColors[splitTailwindClass[1]]?.[splitTailwindClass[2]]
    }

    return backgroundColor ? getContentfulImageBackgroundQuery(backgroundColor) : ''
}
