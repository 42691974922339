import * as React from 'react'
import { IProps, ISidebarContext } from 'interfaces/sidebar-context'

export const CART_TYPES = {
    otc: 'otc',
    rx: 'rx',
} as const

export type TCART_TYPES = (typeof CART_TYPES)[keyof typeof CART_TYPES]

interface ICartSidebarContext extends ISidebarContext {
    openSidebar: (cartType?: TCART_TYPES) => void
    activeCart: TCART_TYPES
    setActiveCart: React.Dispatch<React.SetStateAction<TCART_TYPES>>
}
const CartSidebarContext = React.createContext<ICartSidebarContext>(null)

const CartSidebarContextProvider = ({ children }: IProps): React.ReactElement => {
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false)
    const [activeCart, setActiveCart] = React.useState<TCART_TYPES>('otc')

    const value = React.useMemo(
        () => ({
            open: isSidebarOpen,
            openSidebar: (cartType = CART_TYPES.otc) => {
                setIsSidebarOpen(true)
                setActiveCart(cartType)
            },
            closeSidebar: () => setIsSidebarOpen(false),
            toggleSidebar: () => setIsSidebarOpen(!isSidebarOpen),
            activeCart,
            setActiveCart,
        }),
        [isSidebarOpen, activeCart],
    )

    return <CartSidebarContext.Provider value={value}>{children}</CartSidebarContext.Provider>
}

export const useCartSidebarContext = (): ICartSidebarContext => {
    const cartSidebarContext = React.useContext(CartSidebarContext)

    if (typeof cartSidebarContext === 'undefined') {
        throw new Error('You cannot use useCartSidebarContext outside CartSidebarContextProvider')
    }

    return cartSidebarContext
}

export default CartSidebarContextProvider
