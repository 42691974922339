import * as React from 'react'
import { popupSeenLSKey } from 'misc-variables'
import { getItemFromLS, setItemInLS } from 'lib/util/storage'
import globalManifest from 'data/global-manifest.json'
import { IPageSectionForm } from 'interfaces/page-section-form'
import { IUiResource } from 'interfaces/ui-resource'
import { emailCaptureFormInternalName } from 'constants/contentful'
import { useFeature } from '@growthbook/growthbook-react'
import { hideEmailCapturePopupFeatureName } from 'constants/growthbook'
import { useAuth } from 'components/auth/auth'
import { useCustomerContext } from './customer-context'

interface IEmailCapturePopup {
    form: IPageSectionForm
    brandIcons: IUiResource[]
}

interface IEmailCapturePopupContext {
    isOpen: boolean
    hasBeenDisplayed: boolean
    openPopup: () => void
    closePopup: () => void
    setHasBeenDisplayed: React.Dispatch<React.SetStateAction<boolean>>
    popup: {
        form: IPageSectionForm
        brandIcons: IUiResource[]
    }
    setPopup: React.Dispatch<React.SetStateAction<IEmailCapturePopup>>
    isDisabled?: boolean // don't show popup
    setIsDisabled?: React.Dispatch<React.SetStateAction<boolean>>
    isShouldNotBeDisplayed: boolean
}

interface IProps {
    children: React.ReactNode
}

const { globalForms, brandIcons: globalBrandIcons } = globalManifest
const globalEmailCaptureForm = globalForms.find((f) => f.internalName === emailCaptureFormInternalName)

const EmailCapturePopupContext = React.createContext<IEmailCapturePopupContext>(undefined)

const EmailCapturePopupContextProvider = ({ children }: IProps): React.ReactElement => {
    const { isAuthenticated } = useAuth()
    const { loading: loadingCustomerData } = useCustomerContext()

    const [isOpen, setIsOpen] = React.useState(false)

    // stores if the popup has been shown to the user
    // on page load fetch the state from local storage
    const [hasBeenDisplayed, setHasBeenDisplayed] = React.useState(() => {
        if (typeof window === 'undefined') {
            return false
        }

        return getItemFromLS(popupSeenLSKey) === 'true'
    })

    const [popup, setPopup] = React.useState<IEmailCapturePopup>({
        // @ts-ignore - structure of rich text does not match the interface
        form: globalEmailCaptureForm,
        brandIcons: globalBrandIcons,
    })

    const [isDisabled, setIsDisabled] = React.useState(false)

    const hideEmailCapturePopupFlag = useFeature(hideEmailCapturePopupFeatureName).on

    const isShouldNotBeDisplayed =
        hasBeenDisplayed || isAuthenticated || loadingCustomerData || isDisabled || hideEmailCapturePopupFlag

    const value = React.useMemo<IEmailCapturePopupContext>(
        () => ({
            isOpen,
            hasBeenDisplayed,
            openPopup: () => setIsOpen(true),
            closePopup: () => {
                setIsOpen(false)
                setItemInLS(popupSeenLSKey, 'true')
            },
            setHasBeenDisplayed,
            setPopup,
            popup,
            isDisabled,
            setIsDisabled,
            isShouldNotBeDisplayed,
        }),
        [isOpen, hasBeenDisplayed, popup, isDisabled, isShouldNotBeDisplayed],
    )

    return <EmailCapturePopupContext.Provider value={value}>{children}</EmailCapturePopupContext.Provider>
}

export const useEmailCapturePopupContext = (): IEmailCapturePopupContext => {
    const context = React.useContext(EmailCapturePopupContext)

    if (typeof context === 'undefined') {
        throw Error('You cannot use useEmailCapturePopupContext outside EmailCapturePopupContextProvider')
    }

    return context
}

export default EmailCapturePopupContextProvider
