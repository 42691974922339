import * as React from 'react'

export function PencilIcon({ fill = '#a8a8a8', stroke = '#a8a8a8', width = 20, height = 20, strokeWidth = 3 }) {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none">
            <path
                fill={fill}
                fill-rule="evenodd"
                d="M4.363 15.518a.4.4 0 0 1-.501-.502l.76-2.38 2.121 2.121-2.38.76Z"
                clip-rule="evenodd"
            />
            <path stroke={stroke} strokeWidth={strokeWidth} d="M14.938 4.438 12.88 6.494M5.684 13.693l6.17-6.17" />
        </svg>
    )
}
