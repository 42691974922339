import { colors } from 'theme.cjs'

const HamburgerIcon = ({
    height = '14px',
    width = '20px',
    stroke = colors.mineshaft,
    className = '',
} = {}): React.ReactElement => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 20 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <line
            x1="0.75"
            y1="1.25"
            x2="19.25"
            y2="1.25"
            stroke={stroke}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <line
            x1="0.75"
            y1="8.25"
            x2="19.25"
            y2="8.25"
            stroke={stroke}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <line
            x1="0.75"
            y1="15.25"
            x2="19.25"
            y2="15.25"
            stroke={stroke}
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default HamburgerIcon
