import { colors } from 'theme'
import styles from './style.module.css'
import { IProps as ICheckboxProps } from './checkbox'
import TickMarkIcon from 'icons/tick-mark'

interface IProps extends Omit<ICheckboxProps, 'variant'> {}

export function CheckboxSimple({
    className = '',
    checkMarkClassName = '',
    checkedTickMarkColor = colors.white,
    disabled,
    ...props
}: IProps & React.ComponentProps<'input'>) {
    return (
        <>
            <input
                type="checkbox"
                disabled={disabled}
                {...props}
                className={`${styles['custom-checkbox']} ${className ?? ''}`}
            />
            <div className={`${styles['checkmark']} ${checkMarkClassName}`}>
                <TickMarkIcon stroke={checkedTickMarkColor} strokeWidth={2} />
            </div>
        </>
    )
}
