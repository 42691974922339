import LogoWithSquircleAndTrademark from 'components/logo-with-squircle-and-trademark'
import NewsletterSubscriptionForm from 'components/newsletter-subscription-form/newsletter-subscription-form'
import {
    connectNavigtionInternalName,
    helpFooterNavigationInternalName,
    helpPanelNavigationMenuItemInternalName,
    pharmacyCardNavigationMenuItemInternalName,
} from 'constants/contentful'
import { accountPagePath } from 'constants/url'
import { useHelpSidebar } from 'context/help-sidebar-context'
import { usePharmacyCardPopup } from 'context/pharmacy-card-popup-context'
import globalManifest from 'data/global-manifest.json'
import { getContentfulTransparentBackgroundImageQuery } from 'lib/util/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import * as React from 'react'
import twConfig from 'tailwind-light-config'

const { screens } = twConfig

const { footerNavigation, socialLinks, footerFootLinks, globalAssets, resources: uiResources } = globalManifest

const mdBreakPoint = screens.md

const footerMobileIllustration = globalAssets['Footer Mobile']
const footerDesktopIllustration = globalAssets['Footer Desktop']

const contentfulTransparentBGQuery = getContentfulTransparentBackgroundImageQuery()

const SocialLinks = () => (
    <div className="min-w-full flex lg:min-w-min space-x-5 mt-7.5">
        {socialLinks?.linksCollection.items.map((link) => {
            let img
            switch (link.name) {
                case 'Facebook':
                    img = <img height="20" width="20" alt={link.name} loading="lazy" src="/icons/FacebookIcon.svg" />
                    break
                case 'Instagram':
                    img = <img height="20" width="20" alt={link.name} loading="lazy" src="/icons/InstagramIcon.svg" />
                    break
                case 'Pinterest':
                    img = <img height="20" width="20" alt={link.name} loading="lazy" src="/icons/PinterestIcon.svg" />
                    break
                default:
                    break
            }
            return (
                <a key={link.name} href={link.href} className="cursor-pointer text-md">
                    {img}
                </a>
            )
        })}
    </div>
)

export const Footer = (): React.ReactElement => {
    const { pathname } = useRouter()
    const { open: openHelp } = useHelpSidebar()
    const { open: openPharmacyCardPopup } = usePharmacyCardPopup()
    const clickActionMap = {
        [pharmacyCardNavigationMenuItemInternalName]: openPharmacyCardPopup,
        [helpPanelNavigationMenuItemInternalName]: openHelp,
    }

    return (
        <footer className="section--v2 my-0 pb-7.5 bg-gray-lighter">
            <hr className="border-t border-gray-medium -mx-5 sm:-mx-6 lg:-mx-0" />

            {/* logo + newsletter form */}
            <div className="flex flex-col sm:flex-row sm:justify-between sm:space-x-10 mt-12.5 lg:mt-17.5">
                <LogoWithSquircleAndTrademark className="h-20 lg:h-auto w-auto" />
                <div className="mt-17.5 lg:mt-0">
                    <NewsletterSubscriptionForm />

                    <SocialLinks />
                </div>
            </div>

            {/* illustration */}
            <picture>
                {footerDesktopIllustration?.url && (
                    <>
                        {footerDesktopIllustration.url?.endsWith('.svg') && (
                            <source
                                type="image/svg+xml"
                                srcSet={footerDesktopIllustration.url}
                                media={`(min-width: ${mdBreakPoint})`}
                            />
                        )}

                        <source
                            type="image/webp"
                            media={`(min-width: ${mdBreakPoint})`}
                            srcSet={`
                                ${footerDesktopIllustration.url}?fm=webp&w=1024 1024w,
                                ${footerDesktopIllustration.url}?fm=webp&w=1600 1600w
                            `}
                        />

                        <source
                            type="image/jpg"
                            media={`(min-width: ${mdBreakPoint})`}
                            srcSet={`
                                ${footerDesktopIllustration.url}?fm=jpg&w=1024 1024w,
                                ${footerDesktopIllustration.url}?fm=jpg&w=1600 1600w
                            `}
                        />
                    </>
                )}

                {footerMobileIllustration?.url && (
                    <>
                        {footerMobileIllustration.url?.endsWith('.svg') && (
                            <source type="image/svg+xml" srcSet={footerMobileIllustration.url} />
                        )}

                        <source
                            type="image/webp"
                            srcSet={`
                                ${footerMobileIllustration.url}?fm=webp&w=420 420w,
                                ${footerMobileIllustration.url}?fm=webp&w=768 768w,
                                ${footerMobileIllustration.url}?fm=webp&w=1024 1024w
                            `}
                        />

                        <source
                            type="image/jpg"
                            srcSet={`
                                ${footerMobileIllustration.url}?fm=jpg&w=420 420w,
                                ${footerMobileIllustration.url}?fm=jpg&w=768 768w,
                                ${footerMobileIllustration.url}?fm=jpg&w=1024 1024w
                            `}
                        />
                    </>
                )}

                <img
                    src={(footerMobileIllustration?.url || footerDesktopIllustration?.url) + '?fm=jpg&w=1024'}
                    height={footerMobileIllustration?.height || footerDesktopIllustration?.height}
                    width={footerMobileIllustration?.width || footerDesktopIllustration?.width}
                    aria-hidden
                    loading="lazy"
                    className="mt-15 lg:mt-10 -mx-5 sm:-mx-6 lg:mx-0 w-[calc(100%+2.5rem)] sm:w-[calc(100%+3rem)] max-w-none lg:max-w-full lg:w-full"
                    alt={footerMobileIllustration?.title || footerDesktopIllustration?.title}
                />
            </picture>

            <div className="flex flex-col md:flex-row md:justify-between mt-7.5 lg:mt-8">
                {/* links */}
                <div className="flex flex-col md:flex-row md:space-x-20">
                    {footerNavigation.subNavigationsCollection.items.map((item) => {
                        const links = item.linksCollection.items
                        if (pathname === accountPagePath) {
                            if (item.internalName === connectNavigtionInternalName) {
                                return null
                            }
                        } else {
                            if (item.internalName === helpFooterNavigationInternalName) {
                                return null
                            }
                        }
                        return (
                            <dl key={item.sys.id} className="flex flex-col first:mt-0 mt-10 md:mt-0">
                                <dt className="text-gray text-xsm font-bold uppercase tracking-wider">{item.name}</dt>
                                <div
                                    className={`mt-2.5 columns-[var(--totalColumns)] md:columns-1`}
                                    style={{
                                        ['--totalColumns' as string]: links.length > 4 ? 2 : 1,
                                    }}
                                >
                                    {links.map((link) => {
                                        const clickAction = clickActionMap[link.internalName]

                                        return (
                                            <dd
                                                key={link.name}
                                                className="mt-2.5 first:mt-0 lg:mt-2 lg:first:mt-0 text-xbase font-medium [&>*]:hover:text-shadow-bold"
                                            >
                                                {clickAction ? (
                                                    <button onClick={clickAction}>{link.name}</button>
                                                ) : (
                                                    <Link href={link.href}>{link.name}</Link>
                                                )}
                                            </dd>
                                        )
                                    })}
                                </div>
                            </dl>
                        )
                    })}
                </div>

                <hr className="border-t border-gray-medium md:hidden mt-10" />

                {/* bcorp + legitscript badges */}
                <div className="mt-7 lg:mt-0 flex space-x-7">
                    {/* B-corp badge */}
                    <div>
                        <img
                            alt={uiResources.bCorpLogo.value}
                            src={`${uiResources.bCorpLogo.media.url}?w=200&fm=jpg&${contentfulTransparentBGQuery}`}
                            height={uiResources.bCorpLogo.media.height}
                            width={uiResources.bCorpLogo.media.width}
                            loading="lazy"
                            className="h-[5.625rem] w-auto"
                        />
                    </div>

                    {/* Legit script */}
                    <a
                        href="https://www.legitscript.com/websites/cabinethealth.com/"
                        target="_blank"
                        title="Verify LegitScript Approval"
                        rel="noreferrer"
                    >
                        <img
                            src={`https://static.legitscript.com/seals/10414077.png`}
                            alt="LegitScript approved"
                            width="126"
                            height="86"
                            loading="lazy"
                        />
                    </a>
                </div>
            </div>

            <div className="text-xs md:text-xbase font-mediuma mt-24 md:mt-20 flex flex-col md:flex-row gap-x-7 gap-y-3.5 items-center justify-center flex-wrap">
                {/* copyright message */}
                <p className="tracking-wide text-center">
                    {uiResources['footerCopyrightMessage'].value.replace('{year}', new Date().getFullYear() + '')}
                </p>
                <div className="flex gap-x-6 mx-auto md:mx-0">
                    {footerFootLinks.linksCollection.items.map((link) => (
                        <Link key={link.name} href={link.href}>
                            {link.name}
                        </Link>
                    ))}
                </div>
            </div>
        </footer>
    )
}
