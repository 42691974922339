import { getFormattedNumberWithCurrency } from '@/lib/util/currency'
import Picture from 'components/picture'
import { ILocalProduct } from 'constants/products'
import globalManifest from 'data/global-manifest.json'

interface IProps {
    products: ILocalProduct[]
    isShowProductsOnly?: boolean
}

const { resources: globalUIResources } = globalManifest

export function CabinetSystem({ products, isShowProductsOnly }: IProps) {
    return (
        <div>
            {!isShowProductsOnly && (
                <div className="-tracking-10 text-center mb-4">
                    <div className="text-lg leading-140%">{globalUIResources['cabinetSystemTitle'].value}</div>
                    <div className="text-sm leading-140% text-gray-darker">
                        {globalUIResources['cabinetSystemInfo'].value}
                    </div>
                </div>
            )}
            <ul className="flex flex-col gap-1 mt-1.5">
                {products.map((product) => (
                    <CabinetSystemProduct key={product.name} product={product} />
                ))}
            </ul>

            {!isShowProductsOnly && (
                <>
                    <Picture asset={globalUIResources['cabinetSystemImage'].media} scale={0.5} />
                </>
            )}
        </div>
    )
}

function CabinetSystemProduct({ product }: { product: ILocalProduct }) {
    function renderQuantityLabel() {
        if (product.isGift) {
            return <span>{globalUIResources['giftWithOrderCopy'].value}</span>
        }

        if (product.quantity > 0) {
            return (
                <span>
                    {globalUIResources['quantityShortLabel'].value} {product.quantity}
                </span>
            )
        }
    }
    return (
        <li className="flex gap-2 items-center border border-gray-medium rounded-2.5xl overflow-auto pl-2 pr-5">
            <img src={product.image} alt={product.name} className="h-15 w-auto" />
            <div className="flex flex-col">
                <span className="text-sm leading-140% -tracking-10.5">{product.name}</span>
                <div className="leading-140% -tracking-11 text-xsm text-gray-darker">{renderQuantityLabel()}</div>
            </div>
            <div className="flex flex-col ml-auto text-xsm font-bold -tracking-10 leading-140% text-center">
                {product.price && <s className="text-gray-darker">{getFormattedNumberWithCurrency(product.price)}</s>}
                <span className="uppercase">{globalUIResources['freeLabel'].value}</span>
            </div>
        </li>
    )
}
