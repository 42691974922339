import { useRouter } from 'next/router'
import redirects from 'data/redirects.json'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'

/*
 * This is a workaroud for the redirection bug with open next and SST.
 * Redirects is fetched from Contentful and written to file as a pre build step.
 */
export const Redirects = () => {
    const router = useRouter()

    useIsomorphicLayoutEffect(() => {
        const asPath = router.asPath
        const matchedPath = redirects.find((p) => p.sourcePath === asPath)

        if (matchedPath) {
            router.replace(matchedPath.destinationPath)
        }
    }, [router])

    return null
}
