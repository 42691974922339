import * as React from 'react'
import Toast from 'components/toast'
import Portal from 'components/portal'
import { IToast } from 'interfaces/toast'

interface IProps {
    children: React.ReactNode
}

interface IToastContext {
    showToast: (toast: IToast) => void
}

const ToastContext = React.createContext<IToastContext>(null)

// incrementing unique id
let toastId = 0
const TOAST_LIFESPAN = 4000

export const ToastContextProvider = ({ children }: IProps): React.ReactElement => {
    const [toasts, setToasts] = React.useState<IToast[]>([])

    React.useEffect(() => {
        if (!toasts.length) {
            return
        }

        const timer = setTimeout(() => {
            setToasts((toasts) => {
                const toastsClone = [...toasts]
                toastsClone.shift()

                return toastsClone
            })
        }, TOAST_LIFESPAN)

        return () => {
            clearTimeout(timer)
        }
    }, [toasts])

    const showToast = React.useCallback((toast: IToast) => {
        setToasts((toasts) => [...toasts, { ...toast, id: toastId++ }])
    }, [])

    const value = React.useMemo(
        () => ({
            showToast,
        }),
        [showToast],
    )

    return (
        <ToastContext.Provider value={value}>
            {toasts.length > 0 && (
                <Portal>
                    <div className="fixed right-5 top-5 z-toast overflow-y-auto scrollbar-hidden space-y-2 lg:space-y-3">
                        {toasts.map((toast) => (
                            <Toast type={toast.type} key={toast.id}>
                                <p>{toast.children}</p>
                            </Toast>
                        ))}
                    </div>
                </Portal>
            )}

            {children}
        </ToastContext.Provider>
    )
}

const useToastContext = (): IToastContext => {
    const toastContext = React.useContext(ToastContext)

    if (typeof toastContext === 'undefined') {
        throw new Error('You cannot use useToastContext outside ToastContextProvider')
    }

    return toastContext
}

export default useToastContext
