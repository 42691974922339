import * as React from 'react'
import globalManifest from 'data/global-manifest.json'

interface IProps {
    className?: string
}

const { globalAssets } = globalManifest

const logo = globalAssets['cabinet-squircle-with-trademark']

const LogoWithSquircleAndTrademark = ({ className = '' }: IProps): React.ReactElement => {
    return (
        <picture>
            {logo.url.endsWith('.svg') && <source type="image/svg+xml" srcSet={logo.url} />}
            <source type="image/webp" srcSet={logo.url + '?fm=webp&w=300'} />
            <source type="image/jpeg" srcSet={logo.url + '?fm=jpg&w=300'} />

            <img
                src={logo.url + '?fm=jpg&w=300'}
                alt={logo.description}
                height={logo.height}
                width={logo.width}
                loading="lazy"
                className={className}
            />
        </picture>
    )
}

export default LogoWithSquircleAndTrademark
