import * as React from 'react'

const TickMarkIcon = ({
    stroke = '#010002',
    className = '',
    height = 10,
    width = 10,
    strokeWidth = 1,
} = {}): React.ReactElement => (
    <svg className={className} width={width} height={height} viewBox="0 0 10 8" fill="none">
        <path d="M1 4.25L3.5 6.75L9 1.25" stroke={stroke} strokeWidth={strokeWidth} />
    </svg>
)

export default TickMarkIcon
