import React, { useState, useEffect, useContext, useMemo } from 'react'
import * as Sentry from '@sentry/browser'
import { IHeapContext } from 'interfaces/heap'
import { NEW_CUSTOMER_KEY } from './customer-context'
import { APP_ENV } from 'enum/app-env'

export const HeapContext = React.createContext<IHeapContext>(null)
export const useHeap = (): IHeapContext => useContext(HeapContext)

export interface IProps {
    children: React.ReactNode
}

export function HeapContextProvider({ children }: IProps): React.ReactElement {
    const [loading, setLoading] = useState<boolean>(true)
    const [identity, setIdentity] = useState<null | string>(null)
    const [userId, setUserId] = useState<null | string>(null)
    const [loadingFailed, setLoadingFailed] = useState<boolean>(false)

    // Development id
    let heapAppId = '3469525478'
    if (process.env.APP_ENV === APP_ENV.PRODUCTION) {
        // Production id
        heapAppId = '1627479614'
    }
    useEffect(() => {
        const maxLoadAttempts = 3
        let numAttempts = 0
        const setupInterval = setInterval(() => {
            numAttempts++
            if (typeof window.heap !== 'undefined') {
                // Register the environment
                if (loading && typeof window.heap.load === 'function') {
                    window.heap.load(heapAppId)
                }
            }
            // Heap is loaded at this point
            if (window.heap?.loaded) {
                window.clearInterval(setupInterval)
                // Check if there's a shortcode in query params
                const urlParams = new URLSearchParams(window.location.search)
                // If heap was only partially loaded, heap.loaded is still true
                // Haven't found a good way to check this so we're wrapping in a try...catch
                // If this succeeds, heap successfully loaded
                try {
                    window.heap.clearEventProperties()
                    window.heap.addEventProperties({ [NEW_CUSTOMER_KEY]: true })
                    if (urlParams.has('shortcode')) {
                        window.heap.addEventProperties({ shortcode: urlParams.get('shortcode') })
                    }

                    // If heap has an identity in its cookie, update state with that value
                    const sentryUser = {} as { id: string; email: string }
                    if (window?.heap?.identity && window.heap?.identity.length) {
                        setIdentity(window.heap.identity)
                        sentryUser.email = window.heap.identity
                    }
                    if (window?.heap?.userId) {
                        setUserId(window.heap.userId)
                        sentryUser.id = window.heap.userId
                    }
                    // If we know details about this user, let Sentry know
                    if (Object.keys(sentryUser).length) {
                        Sentry.setUser(sentryUser)
                    }
                    // Not a great indicator of loading, but if we've made it this far in
                    // the try block, Heap loaded.
                    setLoading(false)
                } catch (e) {
                    // If Heap didn't properly load, the above will fail
                    console.error(e)
                    setLoadingFailed(true)
                }
            }
            if (numAttempts === maxLoadAttempts) {
                window.clearInterval(setupInterval)
                // We reached max attempts and still havent loaded the
                // set loadingFailed flag
                if (loading) {
                    setLoadingFailed(true)
                    setLoading(false)
                }
            }
        }, 1000)

        return () => {
            window.clearInterval(setupInterval)
        }
    }, [heapAppId, loading])

    const value = useMemo(() => {
        return {
            loading,
            identity,
            userId,
            loadingFailed,
            // This will keep heap-context state in-sync with window.heap.identity
            // but they have to be set separately
            setIdentity: (email: string) => {
                setIdentity(email)
            },
        }
    }, [loading, identity, userId, loadingFailed])

    return <HeapContext.Provider value={value}>{children}</HeapContext.Provider>
}

/**
 * Head script tag for heap
 * @returns ReactElement
 */
export function SetupHeap(): void {
    const script = document.createElement('script')
    script.setAttribute('async', '')
    script.setAttribute('id', 'heap-script')
    script.type = 'text/javascript'
    script.innerHTML =
        'window.heap=window.heap||[],heap.load=function(e,t){window.heap.appid=e,window.heap.config=t=t||{};var r=document.createElement("script");r.type="text/javascript",r.async=!0,r.src="https://cdn.heapanalytics.com/js/heap-"+e+".js";var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(r,a);for(var n=function(e){return function(){heap.push([e].concat(Array.prototype.slice.call(arguments,0)))}},p=["addEventProperties","addUserProperties","clearEventProperties","identify","resetIdentity","removeEventProperty","setEventProperties","track","unsetEventProperty"],o=0;o<p.length;o++)heap[p[o]]=n(p[o])};'
    document.querySelector('head').appendChild(script)
}
