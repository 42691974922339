import * as React from 'react'

const ColonIcon = ({ className = '', fill = 'white', height = '20', width = '6' } = {}): React.ReactElement => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 6 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="2.93224" cy="2.93175" r="2.93175" fill={fill} />
        <circle cx="2.93175" cy="16.1251" r="2.93175" fill={fill} />
    </svg>
)

export default ColonIcon
