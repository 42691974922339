export interface ILocalProduct {
    name: string
    image: string
    price: number
    quantity?: number
    isGift?: boolean
}

export const refillableGlassBottle: ILocalProduct = {
    name: 'Refillable Glass Bottle',
    image: 'https://images.ctfassets.net/p2eqd3iabyva/acBb8cz5FroydrmfiLDwd/2b4c1f3463ad7b423f8a425e065c0b82/Cabinet_TallJar_empty.png',
    price: 12,
}

export const personalizedToken: ILocalProduct = {
    name: 'Personalized Token',
    image: 'https://images.ctfassets.net/p2eqd3iabyva/2c2OaH827M3mvECNJd7Tzk/087acbb9fa16d859d0c098bdb4755cf4/Product3.png?fit=thumb',
    price: 3,
}

export const travelTin: ILocalProduct = {
    name: 'Mini travel tin',
    image: 'https://images.ctfassets.net/p2eqd3iabyva/529vstt4sLLRleTExyAa6I/b315797ac6770ee861998219650a8c76/Product5.png?fit=thumb',
    price: 5,
    isGift: true,
}
