import * as React from 'react'
import { blogCategoryHomePagePath, blogCategorySubPagePath, homePagePath, ourApproachSitePageUrl } from 'constants/url'
import { useRouter } from 'next/router'
import globalManifest from 'data/global-manifest.json'
import useMobileScreenDetection from 'hooks/useMobileScreenDetection'

interface IProps {
    children: React.ReactNode
}

interface IContext {
    variant: 'opaque' | 'transparent'
    setVariant: React.Dispatch<React.SetStateAction<IContext['variant']>>
}

const { useTransparentHeaderInHomePage, sitePagesWithTransparentNavbar } = globalManifest

const pathsWithTransparentHeader = [blogCategoryHomePagePath, blogCategoryHomePagePath]

if (useTransparentHeaderInHomePage) {
    pathsWithTransparentHeader.push(homePagePath)
}

const routesWithTransparentHeader = [ourApproachSitePageUrl]
const routesWithTransparentHeaderJustMobile = []

sitePagesWithTransparentNavbar.forEach((page) => {
    const pagePath = /pages/ + page.slug
    // if len = 2, then both mobile and desktop
    if (page.useTransparentNavigationIn.length === 2) {
        routesWithTransparentHeader.push(pagePath)
    } else if (page.useTransparentNavigationIn.length === 1) {
        routesWithTransparentHeaderJustMobile.push(pagePath)
    }
})

const HeaderNavbarContext = React.createContext<IContext>(undefined)

export function HeaderNavbarContextProvider({ children }: IProps) {
    const { pathname, asPath } = useRouter()

    const [variant, setVariant] = React.useState<IContext['variant']>('opaque')

    const isMobileScreen = useMobileScreenDetection()

    React.useEffect(() => {
        if (pathname === blogCategoryHomePagePath || pathname === blogCategorySubPagePath) {
            setVariant('transparent')
        } else {
            const asPathWithoutHash = asPath.split('#')[0].split('?')[0]

            const isTransparentHeader =
                pathsWithTransparentHeader.includes(pathname) || routesWithTransparentHeader.includes(asPathWithoutHash)
            const isDynamicHeaderJustMobile = routesWithTransparentHeaderJustMobile.includes(asPathWithoutHash)

            const showTransparentHeader = isTransparentHeader || (isDynamicHeaderJustMobile && !isMobileScreen)

            setVariant(showTransparentHeader ? 'transparent' : 'opaque')
        }
    }, [asPath, pathname, isMobileScreen])

    const value = React.useMemo(() => {
        return {
            variant,
            setVariant,
        }
    }, [variant])

    return <HeaderNavbarContext.Provider value={value}>{children}</HeaderNavbarContext.Provider>
}

export function useHeaderNavbarContext() {
    const context = React.useContext(HeaderNavbarContext)

    if (typeof context === 'undefined') {
        throw Error('You cannot use useHeaderNavbarContext outside HeaderNavbarContextProvider')
    }

    return context
}
