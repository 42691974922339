import * as React from 'react'
import CabinetPharmacyCardPopup from 'components/cabinet-pharmacy-card-popup'
import globalManifest from 'data/global-manifest.json'

const { resources: globalUIResources } = globalManifest

type TPharmaycCardPopupContext = {
    isOpen: boolean
    open: () => void
    close: () => void
}

interface IProps {
    children: React.ReactNode
}

const PharmacyCardPopupContext = React.createContext<TPharmaycCardPopupContext>(null)

export function PharmacyCardPopupContextProvider({ children }: IProps): React.ReactElement {
    const [isOpen, setIsOpen] = React.useState(false)

    const value = React.useMemo<TPharmaycCardPopupContext>(() => {
        return {
            isOpen,
            open: () => setIsOpen(true),
            close: () => setIsOpen(false),
        }
    }, [isOpen])

    return (
        <PharmacyCardPopupContext.Provider value={value}>
            {children}
            {isOpen && (
                <CabinetPharmacyCardPopup
                    cabinetPharmacyCardResource={globalUIResources['cabinetPharmacyCard']}
                    onClose={() => setIsOpen(false)}
                />
            )}
        </PharmacyCardPopupContext.Provider>
    )
}

export function usePharmacyCardPopup() {
    const context = React.useContext(PharmacyCardPopupContext)

    if (context === null) {
        throw Error('usePharmaycCardPopup being called outside of <PharmacyCardPopupContextProvider />')
    }

    return context
}
