import { colors } from 'theme.cjs'
import Logo from './logo'

interface IProps {
    white?: boolean
    className?: string
}

export default function LogoWithTradeMark({ white, className = '' }: IProps): JSX.Element {
    return (
        <div className={`flex justify-center ${className}`}>
            <Logo height={25} width={150} fill={white ? colors.white : colors.mineshaft} />
            <span
                className={`text-xs text-left mt-px ml-2 leading-3 inline-block ${white ? 'text-white' : 'text-black'}`}
            >
                THE SUSTAINABLE
                <br />
                <div>
                    <span className="inline-block">HEALTHCARE CO</span>
                    <sup className="inline-block text-[0.5rem]">TM</sup>
                </div>
            </span>
        </div>
    )
}
