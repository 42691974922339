export enum DIRECTION_VERTICAL {
    TOP = 'top',
    BOTTOM = 'bottom',
    BOTH = 'both',
    MIDDLE = 'middle',
}

export enum DIRECTION_HORIZONTAL {
    LEFT = 'left',
    RIGHT = 'right',
}
