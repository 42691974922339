import { getCookieValue } from '@/lib/util/cookie'
import React, { useContext, useEffect, useMemo, useRef } from 'react'
import { useAuth } from 'components/auth/auth'

interface ApiContextType {
    get: (url: string, params?: any) => Promise<any>
    post: (url: string, data?: any) => Promise<any>
    put: (url: string, data?: any) => Promise<any>
    delete: (url: string) => Promise<any>
}

const ApiContext = React.createContext<ApiContextType | null>(null)

interface ApiProviderProps {
    children: React.ReactNode
}

export const ApiProvider: React.FC<ApiProviderProps> = ({ children }) => {
    const { idToken } = useAuth()
    const initializeCsrfPromise = useRef<Promise<void> | null>(null)

    // Initialize CSRF token
    useEffect(() => {
        const initialize = async () => {
            const token = getCookieValue('csrftoken')

            if (!token) {
                try {
                    await fetch(`${process.env.BACKEND_ENDPOINT}/api/csrf/`, {
                        credentials: 'include',
                    })
                } catch (error) {
                    console.error('Failed to fetch CSRF token:', error)
                }
            }
        }

        initializeCsrfPromise.current = initialize()
    }, [])

    const api = useMemo(() => {
        const createRequest = async (url: string, options: RequestInit = {}, isBlob: boolean = false) => {
            await initializeCsrfPromise.current
            const response = await fetch(`${process.env.BACKEND_ENDPOINT}/api/${url}`, {
                ...options,
                headers: {
                    Authorization: idToken ? `Bearer ${idToken}` : '',
                    'Content-Type': 'application/json',
                    'X-CSRFToken': getCookieValue('csrftoken') || '',
                    ...options.headers,
                },
                credentials: 'include',
            })

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`)
            }

            if (isBlob) {
                return await response.blob()
            }
            return await response.json()
        }

        return {
            get: (
                url: string,
                {
                    params,
                    cfg,
                    isBlob,
                }: {
                    params?: Record<string, string>
                    cfg?: Record<string, unknown>
                    isBlob?: boolean
                } = { isBlob: false },
            ) => {
                if (params) {
                    const queryParams = new URLSearchParams(params)
                    url = `${url}?${queryParams}`
                }
                return createRequest(url, { method: 'GET', ...(cfg || {}) }, isBlob)
            },
            post: (url: string, data?: any) =>
                createRequest(url, {
                    method: 'POST',
                    body: JSON.stringify(data),
                }),
            put: (url: string, data?: any) =>
                createRequest(url, {
                    method: 'PUT',
                    body: JSON.stringify(data),
                }),
            delete: (url: string) => createRequest(url, { method: 'DELETE' }),
        }
    }, [idToken])

    return <ApiContext.Provider value={api}>{children}</ApiContext.Provider>
}

export const useApi = (): ApiContextType => {
    const context = useContext(ApiContext)
    if (!context) {
        throw new Error('useApi must be used within an ApiProvider')
    }
    return context
}
