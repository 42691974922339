import * as React from 'react'

const ArrowRightIcon = ({
    className = '',
    stroke = '#A8A8A8',
    fill = 'none',
    height = '10',
    width = '6',
    strokeWidth = 1,
    pathClassName = '',
    isHover = false,
} = {}): React.ReactElement => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 6 10"
        fill={fill}
        xmlns="http://www.w3.org/2000/svg"
        strokeWidth={strokeWidth}
    >
        <path
            d="M1 9L5 5L1 1"
            stroke={stroke}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            className={`${pathClassName} ${isHover ? 'group-hover:stroke-white' : ''}`}
        />
    </svg>
)

export default ArrowRightIcon
