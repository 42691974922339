import { IImage } from 'interfaces/media'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig

export enum ContentType {
    ImageJpeg = 'image/jpeg',
    ImagePng = 'image/png',
    SVG_XML = 'image/svg+xml',
}
interface IProps {
    asset: IImage
    className?: string
    scale?: number
    // style?: React.StyleHTMLAttributes<HTMLImageElement>
    style?: React.CSSProperties
    loading?: 'lazy' | 'eager'
}
/**
 * Responsive image component w/ max resolution of theme.screens.2xl
 *
 * Pass args.scale to cut the max resolution (e.g. image is half of container width, pass 0.5)
 */
const Picture = ({ asset, className, scale = 1.0, style, loading = 'lazy' }: IProps): React.ReactElement => {
    const screenWidths = {
        '2xl': Math.floor(Number(screens['2xl'].replace('px', '')) * scale),
        lg: Math.floor(Number(screens['lg'].replace('px', '')) * scale),
        md: Math.floor(Number(screens['md'].replace('px', '')) * scale),
        sm: Math.floor(Number(screens['sm'].replace('px', '')) * scale),
    }
    const imgFm = asset.contentType.replace('image/', '')
    return (
        <picture>
            {/* i prefer webp */}
            <source
                type="image/webp"
                // Request size based on screen breakpoints
                srcSet={`
                    ${asset.url}?w=${screenWidths.sm}&fm=webp ${screenWidths.sm}w,
                    ${asset.url}?w=${screenWidths.md}&fm=webp ${screenWidths.md}w,
                    ${asset.url}?w=${screenWidths.md}&fm=webp ${screenWidths.lg}w,
                    ${asset.url}?w=${screenWidths['2xl']}&fm=webp ${screenWidths['2xl']}w,
                `}
                sizes={`(max-width: ${screenWidths.sm}px) ${screenWidths.sm}px,
                    (max-width: ${screenWidths.md}px) ${screenWidths.md}px,
                    (max-width: ${screenWidths.lg}px) ${screenWidths.lg}px,
                    ${screenWidths['2xl']}px`}
            />
            {/* webp is not supported */}
            <source
                type={asset.contentType}
                // Request size based on screen breakpoints
                srcSet={`
                    ${asset.url}?w=${screenWidths.sm}&fm=${imgFm} ${screenWidths.sm}w,
                    ${asset.url}?w=${screenWidths.md}&fm=${imgFm} ${screenWidths.md}w,
                    ${asset.url}?w=${screenWidths.md}&fm=${imgFm} ${screenWidths.lg}w,
                    ${asset.url}?w=${screenWidths['2xl']}&fm=${imgFm} ${screenWidths['2xl']}w,
                `}
                sizes={`(max-width: ${screenWidths.sm}px) ${screenWidths.sm}px,
                    (max-width: ${screenWidths.md}px) ${screenWidths.md}px,
                    (max-width: ${screenWidths.lg}px) ${screenWidths.lg}px,
                    ${screenWidths['2xl']}px`}
            />
            {/* fallback image */}
            <img
                style={style}
                className={className}
                src={`${asset.url}?fm=${imgFm}&w=${screenWidths.lg}`}
                loading={loading}
                alt={asset.description || asset.title}
                height={asset.height}
                width={asset.width}
            />
        </picture>
    )
}

export default Picture
