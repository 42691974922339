import TickMarkIcon from 'icons/tick-mark'
import styles from './style.module.css'
import { colors } from 'theme'
import { IProps as ICheckboxProps } from './checkbox'

interface IProps extends Omit<ICheckboxProps, 'variant'> {}

export function CheckboxWithOutline({
    disabled,
    className = '',
    checkMarkClassName = '',
    checkedTickMarkColor = colors.white,
    ...props
}: IProps & React.ComponentProps<'input'>) {
    return (
        <>
            <input
                type="checkbox"
                disabled={disabled}
                {...props}
                className={`peer ${styles['custom-checkbox']} ${className}`}
            />
            <div
                className={`!rounded-2lg w-6 h-6 border border-gray grid place-content-center peer-checked:[&>div]:bg-black peer-checked:[&_svg]:block ${checkMarkClassName}`}
            >
                <div className="h-[18px] w-[18px] rounded-[7px] grid place-content-center">
                    {/* svg display toggled from peer selected in ancestor */}
                    <TickMarkIcon className="hidden" stroke={checkedTickMarkColor} strokeWidth={2} />{' '}
                </div>
            </div>
        </>
    )
}
