import { IUiResources } from 'interfaces/ui-resource'
import Head from 'next/head'

export default function Meta({ uiResources }: { uiResources: IUiResources }): React.ReactElement {
    return (
        <Head>
            <meta name="viewport" content="width=device-width,initial-scale=1" />
            <link rel="apple-touch-icon" sizes="180x180" href="/favicon/apple-touch-icon.png" />
            <link rel="icon" type="image/png" sizes="32x32" href="/favicon/favicon-32x32.png" />
            <link rel="icon" type="image/png" sizes="16x16" href="/favicon/favicon-16x16.png" />
            <link rel="manifest" href="/favicon/site.webmanifest" />
            <link rel="mask-icon" href="/favicon/safari-pinned-tab.svg" color="#9fc6cc" />
            <link rel="shortcut icon" href="/favicon/favicon.ico" />
            <meta name="msapplication-TileColor" content="#da532c" />
            <meta name="msapplication-config" content="/favicon/browserconfig.xml" />
            <meta name="theme-color" content="#ffffff" />

            <title key="title">{uiResources.defaultPageTitle.value}</title>
            <meta property="og:title" key="og:title" content={uiResources.defaultPageTitle.value as string}></meta>
            <meta property="og:site_name" key="og:site_name" content="Cabinet Health Inc"></meta>
            <meta
                name="description"
                key="description"
                content={uiResources.defaultMetaPageDescription.value as string}
            />
            <meta
                property="og:image"
                content={`${uiResources.defaultSocialImage.media.url}?fm=png&fl=png8`}
                key="og:image"
            />
            <meta
                property="og:image:secure_url"
                content={`${uiResources.defaultSocialImage.media.url}?fm=png&fl=png8`}
                key="og:image:secure_url"
            />
        </Head>
    )
}
