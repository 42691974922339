import * as React from 'react'
import { IProductMembership } from 'interfaces/product/product'
import { getFormattedNumberWithCurrency } from '@/lib/util/currency'
import { membershipPriceDiscountFeatureName } from 'constants/growthbook'
import { FeatureResult, useFeature } from '@growthbook/growthbook-react'
import globalManifest from 'data/global-manifest.json'
import { IFetchMembershipStatus } from 'hooks/useMembershipProduct'
import Loader from 'components/loading/loader'
import Switch from 'components/switch'
import ContentfulRichText from 'components/rich-text'
import { BLOCKS } from '@contentful/rich-text-types'
import TickMarkIcon from 'icons/tick-mark'
import { RenderNode } from '@contentful/rich-text-react-renderer'

export interface IProps {
    membershipProduct: IProductMembership
    fetchMembershipProductStatus?: IFetchMembershipStatus
    fetchMembershipProduct: () => void
    onMembershipSelect: (membership: IProductMembership & { quantity: number }) => void
    selectedMembership: IProductMembership
}

const { resources: globalUIResources, commonCopy: globalCommonCopy } = globalManifest

function getFormattedAmount(amount: number) {
    return getFormattedNumberWithCurrency(amount, undefined, undefined, {
        maximumFractionDigits: Number.isInteger(amount) ? 0 : 2,
    })
}

const membershipBenefitsRenderNode: RenderNode = {
    [BLOCKS.LIST_ITEM]: (_node, children) => (
        <li className="list-none flex gap-1 items-center">
            <TickMarkIcon strokeWidth={2} height={9} width={12} className="hidden xs:block -mt-0.5" />
            <div className="h-1 w-1 bg-gray-darker xs:hidden rounded-full"></div>
            {children}
        </li>
    ),
    [BLOCKS.UL_LIST]: (_node, children) => (
        <div className="justify-center flex">
            <ul className="flex gap-1.5 flex-wrap">{children}</ul>
        </div>
    ),
    [BLOCKS.PARAGRAPH]: (_node, children) => <p className="text-xsm leading-140% -tracking-10.5">{children}</p>,
}

export function CartMembershipUpsell({
    membershipProduct,
    fetchMembershipProductStatus,
    fetchMembershipProduct,
    selectedMembership,
    onMembershipSelect,
}: IProps): React.ReactElement {
    const membershipPriceDiscountPercentage = useFeature(membershipPriceDiscountFeatureName) as FeatureResult<{
        annual: number
        monthly: number
    }>

    const price =
        selectedMembership?.interval === 'year' ? membershipProduct.price_year / 12 : membershipProduct.price_month
    const discountPercentage =
        selectedMembership?.interval === 'year'
            ? membershipPriceDiscountPercentage.value?.annual
            : membershipPriceDiscountPercentage.value?.monthly ?? 0

    const priceBeforeDiscount = price / (1 - discountPercentage / 100)

    if (fetchMembershipProductStatus === 'loading') {
        return (
            <div className="grid -2 h-28 mt-6 place-content-center">
                <Loader secondary />
            </div>
        )
    }

    if (fetchMembershipProductStatus === 'error') {
        return (
            <div className="grid place-content-center h-32 text-sm">
                There was an error fetching memberships.{' '}
                <button type="button" className="underline" onClick={fetchMembershipProduct}>
                    {globalUIResources['retryLabel'].value}
                </button>
            </div>
        )
    }

    return (
        <div data-cy="membership-upsell">
            <div className="flex gap-2 justify-between">
                <div className="p1 lg:text-lg">{globalUIResources['membershipUpsellTitle'].value}</div>
                <div className="p2 font-bold flex items-center">
                    {priceBeforeDiscount > price && (
                        <s className="text-gray-darker">
                            {getFormattedAmount(priceBeforeDiscount)}/
                            {globalUIResources['membershipUpsellCardPerMonthLabel'].value}
                        </s>
                    )}
                    <span className="ml-2">
                        {getFormattedAmount(price)}/{globalUIResources['membershipUpsellCardPerMonthLabel'].value}
                    </span>
                </div>
            </div>
            {selectedMembership?.interval === 'year' && (
                <div className="text-right text-xsm text-gray-darker -tracking-6 leading-120% mt-0.5">
                    <s>{getFormattedAmount(priceBeforeDiscount * 12)}</s>{' '}
                    <span className="font-bold">{getFormattedAmount(membershipProduct.price_year)}</span>{' '}
                    {globalUIResources['membershipLineItemBilledYearlyLabel'].value}
                </div>
            )}
            <Switch
                secondOptionLabel={globalUIResources['membershipUpsellCardYearlyLabel'].value}
                firstOptionLabel={globalUIResources['membershipUpsellCardMonthlyLabel'].value}
                onFirstOptionSelected={() => {
                    onMembershipSelect({
                        ...membershipProduct,
                        quantity: 1,
                        interval: 'month',
                    })
                }}
                onSecondOptionSelected={() => {
                    onMembershipSelect({
                        ...membershipProduct,
                        quantity: 1,
                        interval: 'year',
                    })
                }}
                className="mt-2.5 w-full text-center !rounded-[13px] !p-1 !bg-gray-medium"
                optionClassName="flex-1 !py-0.5 text-black text-sm leading-140% -tracking-10.5"
                toggleClassName="h-[calc(100%-8px)] !top-1 !rounded-2lg bg-white !w-[calc(50%-4px)]"
                activeOptionClassName="!text-black"
                selectedOption={selectedMembership?.interval === 'year' ? 'second' : 'first'}
            />

            <ContentfulRichText
                // @ts-ignore
                content={globalCommonCopy['membershipBenefitsList'].value.json}
                renderNode={membershipBenefitsRenderNode}
                className="mt-2 [&_p]:mb-0 text-center"
            />
        </div>
    )
}
