import * as React from 'react'
import { Cart } from './cart'
import Sidebar from 'components/sidebar'
import { useCartSidebarContext } from 'context/cart-sidebar-context'
import globalManifest from 'data/global-manifest.json'

const { resources: globalUIResources } = globalManifest

const CartSidebar = (): React.ReactElement => {
    const { open, closeSidebar: closeCart } = useCartSidebarContext()

    return (
        <Sidebar
            open={open}
            onClose={closeCart}
            uniqueSidebarIdentifier="cart"
            showTitleDivider
            title={globalUIResources['cartSidebarTitle'].value}
        >
            <Cart />
        </Sidebar>
    )
}

export default CartSidebar
