import * as React from 'react'

type THelpSidebarContext = {
    isOpen: boolean
    open: () => void
    close: () => void
}

interface IProps {
    children: React.ReactNode
}

const HelpSidebarContext = React.createContext<THelpSidebarContext>(null)

export function HelpSidebarContextProvider({ children }: IProps): React.ReactElement {
    const [isOpen, setIsOpen] = React.useState(false)

    const value = React.useMemo<THelpSidebarContext>(() => {
        return {
            isOpen,
            open: () => setIsOpen(true),
            close: () => setIsOpen(false),
        }
    }, [isOpen])

    return <HelpSidebarContext.Provider value={value}>{children}</HelpSidebarContext.Provider>
}

export function useHelpSidebar() {
    const context = React.useContext(HelpSidebarContext)

    if (context === null) {
        throw Error('Cannot use useHelpSidebar outisde of <HelpSidebarContextProvider />')
    }

    return context
}
