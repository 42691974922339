import * as React from 'react'

interface IProps {
    children: React.ReactNode
    className?: string
}

// todo: position overlay with surrounding space availability
export function Overlay({ children, className = '' }: IProps) {
    return (
        <div className={`absolute top-8 bg-white rounded-md overflow-hidden z-overlay shadow-overlay ${className}`}>
            {children}
        </div>
    )
}
