export const isString = (val: any) => {
    return typeof val === 'string' || val instanceof String
}

export const isNil = (val: any) => {
    return val === null || val === undefined
}

export const isPositiveInteger = (val: any) => {
    return Number.isInteger(val) && val > 0
}

/*
Filter an object to only the pickKey values.
Nested value retrieval is not supported
 */
export const pick = (val: object, pickKeys: string[]) => {
    return pickKeys.reduce((filteredVal: object, pickKey: string) => {
        filteredVal[pickKey] = val[pickKey]
        return filteredVal
    }, {})
}

/*
Filter an object for values that are not null or undefined
 */
export const getObjectRemoveNils = (val: object) => {
    return Object.entries(val).reduce((finalDict, [key, val]) => {
        if (!isNil(val)) {
            finalDict[key] = val
        }
        return finalDict
    }, {})
}
