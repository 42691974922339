import * as React from 'react'
import { IBanner } from 'interfaces/banner'
import { useRouter } from 'next/router'
import { accountPagePath } from 'constants/url'
import globalManifest from 'data/global-manifest.json'

interface IBannerContext {
    closeBanner: () => void
    banner: IBanner
}

interface IProps {
    children: React.ReactNode
}

const { banners } = globalManifest

const BannerContext = React.createContext<IBannerContext>(null)

export const BannerContextProvider = ({ children }: IProps): React.ReactElement => {
    const { pathname } = useRouter()
    const [banner, setBanner] = React.useState<IBanner>()

    // we want to show banner on each page, if a banner is closed
    // and the user navigates to a page with a different banner then
    // we'd want to show the different banner, to do this we're using
    // the state below to track which ones have been closed
    const [closedBanners, setClosedBanners] = React.useState<string[]>([]) // using banner.name

    React.useEffect(() => {
        // banners have a start and end date
        // filter out banners that haven't started or have ended
        const activeBanners = banners?.filter((banner) => {
            const now = new Date()
            return now <= new Date(banner?.activeEndDate) && now >= new Date(banner?.activeStartDate)
        }) as IBanner[]

        // account page can have a separate banner, this is a quick solution
        // ideally we'd want to set some field in banner in Contentful or allow
        // each page to set banner
        const banner = pathname === accountPagePath ? activeBanners?.[1] ?? activeBanners?.[0] : activeBanners?.[0]

        if (closedBanners.includes(banner?.name)) {
            return
        }

        setBanner(banner)
    }, [pathname, closedBanners])

    const value = React.useMemo(
        () => ({
            closeBanner: () => {
                setBanner(null)
                setClosedBanners((b) => Array.from(new Set([...b, banner?.name])))
            },
            banner,
        }),
        [banner],
    )

    return <BannerContext.Provider value={value}>{children}</BannerContext.Provider>
}

export const useBannerContext = (): IBannerContext => {
    const bannerContext = React.useContext(BannerContext)

    if (typeof bannerContext === 'undefined') {
        throw new Error('You cannot use useBannerContext outside BannerContextProvider')
    }

    return bannerContext
}

export default BannerContextProvider
