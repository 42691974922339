import * as React from 'react'
import ContentfulRichText from 'components/rich-text'
import ColonIcon from 'icons/colon-icon'
import { colors } from 'theme.cjs'
import { BLOCKS, Document } from '@contentful/rich-text-types'

interface IProps {
    content: Document | React.ReactElement
    colonIconFill?: string
    colonIconHeight?: string
}

const TitleElement = ({ children }): React.ReactElement => (
    <span className="font-semibold text-xsm lg:text-sm tracking-widest block">{children}</span>
)

const titleRenderOption = {
    renderNode: {
        // eslint-disable-next-line react/display-name
        [BLOCKS.PARAGRAPH]: (_node, children) => <TitleElement>{children}</TitleElement>,
    },
}

const TitleWithColonIcon = ({ content, colonIconFill, colonIconHeight }: IProps): React.ReactElement => (
    <div className="flex items-center">
        <ColonIcon fill={colonIconFill || colors.mineshaft} height={colonIconHeight} />
        <span className="ml-3.5">
            {React.isValidElement(content) ? (
                <TitleElement>{content}</TitleElement>
            ) : (
                <ContentfulRichText content={content as Document} renderOptions={titleRenderOption} />
            )}
        </span>
    </div>
)

export default TitleWithColonIcon
