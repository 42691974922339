/* eslint-disable no-restricted-syntax */
function isStorageAccessDenied() {
    return !window.navigator?.cookieEnabled
}

export const setItemInLS = (key: string, value: string): void => {
    if (isStorageAccessDenied()) {
        console.warn('Local storage access is denied by the user')
        return
    }

    localStorage.setItem(key, value)
}

export const getItemFromLS = (key: string): string => {
    if (isStorageAccessDenied()) {
        console.warn('Local storage access is denied by the user')
        return
    }

    return localStorage.getItem(key)
}

export const removeItemFromLS = (key: string): void => {
    if (isStorageAccessDenied()) {
        console.warn('Local storage access is denied by the user')
        return
    }

    localStorage.removeItem(key)
}

export const setItemInSS = (key: string, value: string): void => {
    if (isStorageAccessDenied()) {
        console.warn('Session storage access is denied by the user')
        return
    }

    sessionStorage.setItem(key, value)
}

export const getItemFromSS = (key: string): string => {
    if (isStorageAccessDenied()) {
        console.warn('Session storage access is denied by the user')
        return
    }

    return sessionStorage.getItem(key)
}

export const removeItemFromSS = (key: string): void => {
    if (isStorageAccessDenied()) {
        console.warn('Session storage access is denied by the user')
        return
    }

    sessionStorage.removeItem(key)
}
