import * as React from 'react'
import { useRXCartContext } from 'context/rx-cart-context'
import { StripeCheckout } from 'components/checkout/stripe-checkout'
import { CHECKOUT_SOURCE } from 'components/checkout/checkout-form'
import { useUserContext } from 'context/user-context'

export function CartPayment() {
    const { order } = useRXCartContext()
    const {
        user: { first_name, last_name },
    } = useUserContext()

    return (
        <div className="p-5" data-cy="cart-payment">
            <StripeCheckout
                totalPrice={order.totalPrice}
                clientSecret={order?.stripe_client_secret}
                source={CHECKOUT_SOURCE.CART}
                // assumption: if no first name then perhaps no last name as well
                customerName={first_name ? `${first_name} ${last_name}` : ''}
            />
        </div>
    )
}
