// pathname (as exposed from useRouter().pathname)
export const homePagePath = '/'
export const accountPagePath = '/account'
export const blogCategoryHomePagePath = '/blogs'
export const blogCategorySubPagePath = '/blogs/[slug]'
export const flowPagePath = '/flow/[slug]'

// slugs
export const postPaymentFlowSlug = 'post-payment'
export const rxNonContextFlowSlug = 'rx-get-started'
export const rxContextFlowSlug = 'rx-get-started-ctx'

// urls
export const postPaymentFlowUrl = `/flow/${postPaymentFlowSlug}`
export const rxNonContextFlowUrl = `/flow/${rxNonContextFlowSlug}`
export const ourApproachSitePageUrl = '/pages/our-approach'
