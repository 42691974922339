import styles from './style.module.css'

interface IProps {
    secondary?: boolean
    dotClassname?: string
    className?: string
}

const Loader = ({ secondary, dotClassname = '', className = '' }: IProps): React.ReactElement => {
    return (
        <div
            data-cy="loader"
            className={`${styles['lds-ellipsis']} ${className} ${
                secondary ? `text-black ${styles['lds-ellipsis-secondary']}` : 'text-white'
            }`}
        >
            <div className={dotClassname}></div>
            <div className={dotClassname}></div>
            <div className={dotClassname}></div>
        </div>
    )
}

export default Loader
