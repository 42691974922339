import Container from './container'

export default function PreviewAlert() {
    return (
        <div className="border-b bg-accent-7 border-accent-7 bg-gray-lighter z-30 sticky top-[var(--pageHeaderHeight)]">
            <Container>
                <div className="py-2 text-center text-sm">
                    <>
                        This is page is a preview.
                        {/* eslint-disable-next-line @next/next/no-html-link-for-pages */}
                        <a
                            href="/api/exit-preview"
                            className="underline hover:text-cyan duration-200 transition-colors"
                        >
                            Click here
                        </a>{' '}
                        to exit preview mode.
                    </>
                </div>
            </Container>
        </div>
    )
}
