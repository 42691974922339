import * as React from 'react'
import { IProps, ISidebarContext } from 'interfaces/sidebar-context'

const MobileNavSidebarContext = React.createContext<ISidebarContext>(null)

export function MobileNavSidebarContextProvider({ children }: IProps): React.ReactElement {
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false)

    const value = React.useMemo(
        () => ({
            open: isSidebarOpen,
            openSidebar: () => setIsSidebarOpen(true),
            closeSidebar: () => setIsSidebarOpen(false),
            toggleSidebar: () => setIsSidebarOpen(!isSidebarOpen),
        }),
        [isSidebarOpen],
    )

    return <MobileNavSidebarContext.Provider value={value}>{children}</MobileNavSidebarContext.Provider>
}

export function useMobileNavSidebarContext(): ISidebarContext {
    const sidebarContext = React.useContext(MobileNavSidebarContext)

    if (typeof sidebarContext === 'undefined') {
        throw new Error('You cannot use useSecondaryNavSidbarContext outside SecondaryNavSidbarContextProvider')
    }

    return sidebarContext
}
