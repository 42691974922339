import * as React from 'react'
import { ApolloProvider } from '@apollo/client'
import { useAuth } from 'components/auth/auth'
import { createApolloClient } from 'lib/apollo-client'

interface IProps {
    children: React.ReactNode
}

export const ApolloContextProvider = ({ children }: IProps): React.ReactElement => {
    const { idToken } = useAuth()
    const [client, setClient] = React.useState(() => createApolloClient())

    // only create client when token changes
    React.useEffect(() => {
        setClient(createApolloClient(idToken))
    }, [idToken])

    return <ApolloProvider client={client}>{children}</ApolloProvider>
}
