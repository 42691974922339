import { colors } from 'theme.cjs'

const ProfileIcon = ({ fill = colors.mineshaft, height = 21, width = 22, className = '' } = {}): React.ReactElement => (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none" className={className}>
        <circle cx="10.999" cy="8.091" r="5.091" fill={fill} />
        <path d="M3 17.818a5 5 0 0 1 5-5h6a5 5 0 0 1 5 5V19H3v-1.182Z" fill={fill} />
    </svg>
)

export default ProfileIcon
