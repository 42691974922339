/* eslint-disable react/display-name */
import * as React from 'react'
import globalManifest from 'data/global-manifest.json'
import CabinetSquareLogo from 'icons/cabinet-square-logo'
import Button from 'components/buttons/button'
import { BLOCKS } from '@contentful/rich-text-types'
import ContentfulRichText from 'components/rich-text'
import styles from './style.module.css'
import { useCustomerContext } from 'context/customer-context'
import { userSubscribeEventName } from 'misc-variables'
import { fetchWithTimeout } from '@/lib/util/fetch'

const { resources: uiResources, commonCopy } = globalManifest

const renderOptions = {
    renderNode: {
        [BLOCKS.HEADING_3]: (_node, children) => <h3 className="mb-0 mt-7 lg:mt-6 text-3.25xl">{children}</h3>,
        [BLOCKS.PARAGRAPH]: (_node, children) => <p className="mt-5 lg:mt-4 text-base">{children}</p>,
    },
}

const subscribeLocationKey = 'banner_modal'

const NotificationSignupForm = (): React.ReactElement => {
    const [email, setEmail] = React.useState('')

    const [signUpSuccess, setSignUpSuccess] = React.useState(null)
    const [signUpError, setSignUpError] = React.useState(null)

    const [isSigningUp, setIsSigningUp] = React.useState(false)

    const { track } = useCustomerContext()

    const signUp = async () => {
        try {
            setIsSigningUp(true)
            const response = await fetchWithTimeout('/api/subscribe', {
                method: 'POST',
                body: JSON.stringify({ email, subscribeLocation: subscribeLocationKey }),
            })

            if (response.ok) {
                setSignUpSuccess(true)

                track(`Any Page - ${userSubscribeEventName} - Banner Popup`, {
                    subscribeLocation: subscribeLocationKey,
                })
            } else {
                setSignUpError(true)
            }
        } catch (e) {
            setSignUpError(e)
        } finally {
            setIsSigningUp(false)
        }
    }

    const handleFormSubmit = (e: React.FormEvent) => {
        e.preventDefault()
        signUp()
    }

    return (
        <div className="px-5 py-7 lg:p-12 flex flex-col items-center text-center rounded-md">
            <CabinetSquareLogo className="mt-5 lg:mt-0" />
            {!signUpError && !signUpSuccess && (
                <>
                    <ContentfulRichText
                        //  @ts-ignore
                        content={commonCopy?.launchNotificationSignUpMessage?.value?.json}
                        renderOptions={renderOptions}
                    />
                    <form onSubmit={handleFormSubmit} className="mt-5 lg:mt-6 flex justify-center lg:justify-start">
                        <input
                            data-cy="email-input"
                            className={`ring-inset pl-6 py-2.5 pr-3 w-1/2 sm:w-auto grow sm:grow-0 rounded-l-full border border-r-0 border-gray`}
                            placeholder={uiResources?.enterEmailLabel?.value?.toString()}
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <Button
                            data-cy="newsletter-subscribe-button"
                            loading={isSigningUp}
                            className={`ring-inset pr-6 pl-3.5 py-2.5 rounded-tl-none rounded-bl-none min-w-32 ${styles['notify-button']}`}
                        >
                            {uiResources?.notifyMeLabel?.value}
                        </Button>
                    </form>
                </>
            )}
            {signUpSuccess && (
                <>
                    <ContentfulRichText
                        // @ts-ignore
                        content={commonCopy?.launchNotificationSignupSuccessMessage?.value?.json}
                        renderOptions={renderOptions}
                    />
                </>
            )}
            {signUpError && (
                <>
                    <ContentfulRichText
                        // @ts-ignore
                        content={commonCopy?.newsLetterSubscriptionErrorMessage?.value?.json}
                        renderOptions={renderOptions}
                    />
                </>
            )}
        </div>
    )
}

export default NotificationSignupForm
