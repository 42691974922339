import * as React from 'react'
import {
    rxConsentChildResistantOptionInternalName,
    rxConsentNonChildResistantOptionInternalName,
} from 'constants/contentful'
import ContentfulRichText from 'components/rich-text'
import { BLOCKS, INLINES } from '@contentful/rich-text-types'
import { RenderNode } from '@contentful/rich-text-react-renderer'
import { IFormInput } from 'interfaces/form-input'
import ErrorMessage from 'components/error-message'
import { BloatedButton } from 'components/buttons/button'
import { CheckboxWithOutline } from 'components/checkbox'
import { IPageSectionForm } from 'interfaces/page-section-form'

interface IProps {
    form: IPageSectionForm
    onAccept: () => void
    buttonWrapperClassName?: string
    headerClassName?: string
    bodyWrapperClassName?: string
}

enum CHECKBOX_OPTIONS {
    childResitantPouch = rxConsentChildResistantOptionInternalName,
    nonChildResitantPouch = rxConsentNonChildResistantOptionInternalName,
}

export function RxConsent({
    form,
    onAccept,
    buttonWrapperClassName = '',
    headerClassName = '',
    bodyWrapperClassName = '',
}: IProps) {
    const [checkedInput, setCheckedInput] = React.useState<CHECKBOX_OPTIONS>(CHECKBOX_OPTIONS.nonChildResitantPouch)

    const { header, paragraph, submitButtonText } = form

    const headerRenderNode: RenderNode = {
        [BLOCKS.PARAGRAPH]: (_, children) => (
            <h2 className={`text-2.75xl text-center ${headerClassName}`}>{children}</h2>
        ),
    }

    const paragraphRenderNode: RenderNode = {
        [BLOCKS.OL_LIST]: (_node, children) => (
            <ol className="flex flex-col space-y-4 ml-5 list-[decimal-leading-zero] marker:text-xs marker:font-bold">
                {children}
            </ol>
        ),
        [INLINES.HYPERLINK]: (node, children) => {
            return (
                <a href={node.data.uri} target="_blank" rel="noreferrer" className="underline">
                    {children}
                </a>
            )
        },
        [INLINES.EMBEDDED_ENTRY]: (node) => {
            const inputEntry = paragraph.links.entries.inline.find((l) => l.sys.id === node.data.target.sys.id)
            return (
                <CheckboxInput
                    input={inputEntry}
                    checkedInput={checkedInput}
                    onChange={() => setCheckedInput(inputEntry.internalName)}
                />
            )
        },
        [BLOCKS.PARAGRAPH]: (_, children) => <div className="text-sm leaidng-120% -tracking-6 pl-1.5">{children}</div>,
    }

    return (
        <>
            <div className={`flex-grow ${bodyWrapperClassName}`}>
                <div>
                    {header?.json && (
                        <ContentfulRichText content={header.json} renderNode={headerRenderNode} ignoreMarkdownStyles />
                    )}
                    {paragraph?.json && (
                        <ContentfulRichText
                            // @ts-ignore
                            content={paragraph.json}
                            renderNode={paragraphRenderNode}
                            className="mt-3.5"
                            ignoreMarkdownStyles
                        />
                    )}
                </div>
            </div>
            <div className={buttonWrapperClassName}>
                <BloatedButton
                    className="w-full"
                    onClick={onAccept}
                    disabled={checkedInput !== CHECKBOX_OPTIONS.nonChildResitantPouch}
                    data-cy="consent-accept-button"
                >
                    {submitButtonText}
                </BloatedButton>
            </div>
        </>
    )
}

function CheckboxInput({
    input,
    checkedInput,
    ...props
}: {
    input: IFormInput
    checkedInput: CHECKBOX_OPTIONS
} & React.ComponentProps<'input'>) {
    const checked = checkedInput === input.internalName

    return (
        <>
            <label
                className={`p-4 bg-white rounded-2.5xl w-full flex justify-between items-center mt-2 cursor-pointer border ${
                    checked ? 'border-black' : 'border-gray-medium'
                }`}
                data-cy="checkbox-label"
            >
                <div className="text-xs leading-120% -tracking-6">
                    <div className="font-bold">{input.label}</div>
                    <div className="text-gray mt-2">{input.placeholder}</div>
                </div>
                <CheckboxWithOutline
                    {...props}
                    checked={checked}
                    label={undefined /* setting undefined becasue checkbox already inside label */}
                />
            </label>
            {checkedInput === CHECKBOX_OPTIONS.childResitantPouch && (
                <ErrorMessage className="mt-1 block">{input.failureMessage}</ErrorMessage>
            )}
        </>
    )
}
