/* eslint-disable react/display-name */
import * as React from 'react'
import { documentToReactComponents, Options, RenderNode } from '@contentful/rich-text-react-renderer'
import { BLOCKS, Document, INLINES } from '@contentful/rich-text-types'
import markdownStyles from './markdown-styles.module.css'
import Picture, { ContentType } from 'components/picture'
import Link from 'next/link'
import { IImage } from 'interfaces/media'
import { parseColorCodedString, parseLineBreaks, parseSuperscript } from 'lib/util/rich-text-parser'
import Video from './video/video'

interface Props {
    className?: string
    content: Document
    renderOptions?: Options
    // pass renderNode to implement custom renderNode
    // and not override every default setting
    renderNode?: RenderNode
    renderPicture?: (image: IImage) => React.ReactElement
    links?: any
    parseColor?: boolean
    parseSuperscriptTag?: boolean // false by default
    ignoreMarkdownStyles?: boolean
}

export function defaultRenderOptions(links, renderNode: RenderNode, renderPicture) {
    if (typeof renderNode !== 'object' || Array.isArray(renderNode)) {
        renderNode = {}
    }

    // create an asset map
    const assetMap = new Map()

    // loop through the linked assets and add them to a map
    if (links?.assets) {
        for (const asset of links?.assets?.block) {
            assetMap.set(asset.sys.id, asset)
        }
    }

    // create an entry map
    const entryMap = new Map()

    // loop through the block linked entries and add them to the map
    if (links?.entries?.block) {
        for (const entry of links?.entries?.block) {
            if (entry.sys?.id) {
                entryMap.set(entry.sys.id, entry)
            }
        }
    }

    // loop through the inline linked entries and add them to the map
    if (links?.entries?.inline) {
        for (const entry of links?.entries?.inline) {
            entryMap.set(entry.sys.id, entry)
        }
    }

    // loop through the inline linked entries and add them to the map
    if (links?.entries?.hyperlink) {
        for (const entry of links?.entries?.hyperlink) {
            entryMap.set(entry?.sys?.id, entry)
        }
    }

    return {
        renderNode: {
            [BLOCKS.PARAGRAPH]: (_node, children): React.ReactElement => <p className="mb-4">{children}</p>,
            [BLOCKS.EMBEDDED_ASSET]: (node) => {
                // find the asset in the assetMap by ID
                const asset = assetMap.get(node.data.target.sys.id)

                switch (asset?.contentType) {
                    case 'video/mp4':
                        return (
                            <Video width="100%" height="100%" controls>
                                <source src={asset.url} type="video/mp4" />
                            </Video>
                        )
                    case ContentType.ImagePng:
                    case ContentType.ImageJpeg:
                    case ContentType.SVG_XML:
                        if (typeof renderPicture === 'function') {
                            return renderPicture(asset)
                        }

                        // Image format
                        return <Picture asset={asset} />
                    default:
                        return null
                }
            },
            [BLOCKS.OL_LIST]: (_node, children) => <ol className="list-decimal">{children}</ol>,
            [BLOCKS.UL_LIST]: (_node, children) => <ul className="list-disc">{children}</ul>,
            [INLINES.ENTRY_HYPERLINK]: (node) => {
                const linkedEntry = entryMap.get(node.data.target.sys.id)

                let slug: string

                switch (linkedEntry?.__typename) {
                    case 'ProductCollection':
                        slug = `/collections/${linkedEntry?.slug}`
                        break

                    case 'Medicine':
                        slug = `/medicines/${linkedEntry?.slug}`
                        break

                    case 'Link':
                        slug = linkedEntry?.href
                        break

                    case 'SitePage':
                        slug = `/pages/${linkedEntry?.slug}`
                        break

                    case 'ProductPage':
                        slug = `/products/${linkedEntry?.slug}`
                        break

                    default:
                        slug = linkedEntry?.href || linkedEntry?.slug
                }

                if (!slug) {
                    return <span className="font-bold underline visited:text-gray">{node?.content?.[0]?.value}</span>
                }

                return (
                    <Link href={slug} className="font-bold underline visited:text-gray">
                        {node?.content?.[0]?.value}
                    </Link>
                )
            },
            [INLINES.HYPERLINK]: (link, children) => (
                <a href={link.data.uri} className="font-bold underline visited:text-gray">
                    {children}
                </a>
            ),
            ...renderNode,
        },
    }
}

const ContentfulRichText = ({
    className,
    content,
    renderOptions,
    renderNode,
    links,
    parseColor = true,
    renderPicture,
    parseSuperscriptTag,
    ignoreMarkdownStyles,
}: Props): React.ReactElement => {
    const DEFAULT_OPTIONS: Options = defaultRenderOptions(links, renderNode, renderPicture)
    const options = {
        ...DEFAULT_OPTIONS,
        ...renderOptions,
    }

    const renderText = (text: string): React.ReactNode => {
        // replace \n with <br /> and parse color
        const textWithBR = parseLineBreaks(text)

        let parsedText: string | (string | React.ReactElement)[] = textWithBR

        if (parseColor) {
            parsedText = parseColorCodedString(parsedText)
        }

        if (parseSuperscriptTag) {
            parsedText = parseSuperscript(parsedText)
        }

        return parsedText
    }

    // don't override renderText if already defined
    // if renderText has been passed as a render option
    // then it needs to call the parse color function itself
    if (!options.hasOwnProperty('renderText')) {
        options['renderText'] = renderText
    }

    return (
        <div className={className || (ignoreMarkdownStyles ? '' : markdownStyles['markdown'])}>
            {documentToReactComponents(content, options)}
        </div>
    )
}

export default ContentfulRichText
