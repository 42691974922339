import * as React from 'react'
import { createPortal } from 'react-dom'

interface IProps {
    children: React.ReactNode
}

const Portal = ({ children }: IProps): React.ReactPortal => {
    const [isMounted, setIsMounted] = React.useState(false)

    React.useEffect(() => {
        setIsMounted(true)
    }, [])

    return isMounted ? createPortal(children, document.getElementById('__portal_root')) : null
}

export default Portal
