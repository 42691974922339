import * as React from 'react'
import Link from 'next/link'
import { MyCabinetButton } from 'components/buttons/my-cabinet'
import { IconButton } from 'components/buttons/button'
import Logo from 'components/logo'
import { useCartSidebarContext } from 'context/cart-sidebar-context'
import CartIcon from 'icons/cart-icon'
import { useMobileNavSidebarContext } from 'context/mobile-nav-sidebar-context'
import HamburgerIcon from 'icons/hamburger'
import { useRouter } from 'next/router'
import { colors } from 'theme.cjs'
import { useBannerContext } from 'context/banner-context'
import globalManifest from 'data/global-manifest.json'
import { useRXCartContext } from 'context/rx-cart-context'
import { useHelpSidebar } from 'context/help-sidebar-context'
import { accountPagePath, flowPagePath } from 'constants/url'
import { useHeaderNavbarContext } from 'context/header-navbar-context'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import {
    navigationAddPrescriptionLinkInternalName,
    navigationHelpLinkInternalName,
    navigationJoinCabinetLinkInternalName,
} from 'constants/contentful'
import { useAuth } from 'components/auth/auth'
import useMobileScreenDetection from 'hooks/useMobileScreenDetection'

const { resources, desktopNavbarLinks } = globalManifest
const dessktopNavbarLeftSideLinks = desktopNavbarLinks.linksCollection.items.filter(
    (link) =>
        ![navigationJoinCabinetLinkInternalName, navigationAddPrescriptionLinkInternalName].includes(link.internalName),
)
const addPrescriptionLink = desktopNavbarLinks.linksCollection.items.find(
    (link) => link.internalName === navigationAddPrescriptionLinkInternalName,
)

const joinCabinetLink = desktopNavbarLinks.linksCollection.items.find(
    (link) => link.internalName === navigationJoinCabinetLinkInternalName,
)

const linkClassName = 'text-xbase leading-110% -tracking-11'

export function HeaderNav(): React.ReactElement {
    const { toggleSidebar: toggleCart } = useCartSidebarContext()
    const { toggleSidebar: toggleSecondaryNav } = useMobileNavSidebarContext()
    const { totalLineItem: totalRXLineItems } = useRXCartContext()
    const { open: openHelpSidebar } = useHelpSidebar()
    const { pathname } = useRouter()
    const { variant } = useHeaderNavbarContext()
    const { banner } = useBannerContext()
    const { isAuthenticated } = useAuth()

    const [markerEl, setMarkerEl] = React.useState<HTMLElement>()

    const markerElRef: React.RefCallback<HTMLElement> = React.useCallback((node) => {
        if (node !== null) {
            setMarkerEl(node)
        }
    }, [])

    const intersectionObserverOptions: IntersectionObserverInit = React.useMemo(() => {
        if (typeof document === 'undefined') {
            return
        }

        const bannerHeight = Number(document.documentElement.style.getPropertyValue('--bannerHeight').replace('px', ''))

        const rootMargin = banner?.stickyBanner ? `${bannerHeight}px 0px 0px 0px` : '0px 0px 0px 0px'

        return {
            rootMargin,
        }
    }, [banner?.stickyBanner])

    const { entry } = useIntersectionObserver({ element: markerEl, options: intersectionObserverOptions })

    const stickyBanner = banner?.stickyBanner

    const headerPositionClassname = 'sticky top-[var(--top)]'
    let headerBackgroundColor = 'bg-white'

    const isTransparentHeader = variant === 'transparent'
    const isHeaderStuck = !entry?.isIntersecting
    const headerHeightClassName = isTransparentHeader ? 'h-0' : 'h-[var(--pageHeaderHeight)]'

    // use light or dark colors for the contents of the header
    const isUseLightColors = isTransparentHeader && !isHeaderStuck

    // background of header needs to be opposite color it's consents
    if (isUseLightColors) {
        headerBackgroundColor = 'bg-transparent'
    }

    let headerBorderColor = 'border-gray-medium'

    if (isUseLightColors) {
        headerBorderColor = 'border-white'
    }

    return (
        <>
            <div className="h-[0px]" ref={markerElRef} />
            <header
                className={`cabinet-container w-full z-header ${headerHeightClassName} ${headerPositionClassname}`}
                style={{
                    ['--top' as string]: stickyBanner ? 'var(--bannerHeight)' : '0',
                }}
            >
                <div className="relative">
                    <div className={`transition-colors ${headerBackgroundColor}`}>
                        <div
                            className={`flex items-center gap-4 xl:gap-10 px-5 sm:px-6 xl:px-15 
                                h-[var(--pageHeaderHeight)]
                                border-b ${headerBorderColor}
                                box-content
                            `}
                        >
                            <IconButton
                                data-cy="toggle-secondary-nav-button"
                                onClick={toggleSecondaryNav}
                                label={resources?.secondaryNavTitle?.value}
                                className="px-0 xl:hidden"
                            >
                                <HamburgerIcon stroke={isUseLightColors ? colors.white : colors.mineshaft} />
                            </IconButton>

                            <Link
                                href="/"
                                aria-label={resources?.logoLinkLabel?.value}
                                data-cy="header-logo"
                                className="flex-grow xl:flex-grow-0"
                            >
                                <Logo
                                    fill={isUseLightColors ? colors.white : colors.mineshaft}
                                    className="h-[18px] w-auto xl:h-6"
                                />
                            </Link>

                            <div
                                className={`hidden xl:flex flex-grow gap-5 ${
                                    isUseLightColors ? 'text-white' : 'text-black'
                                }`}
                            >
                                {dessktopNavbarLeftSideLinks.map((link, index) => {
                                    if (link.internalName === navigationHelpLinkInternalName) {
                                        if (pathname !== accountPagePath) {
                                            return null
                                        }

                                        return (
                                            <button key={index} className={linkClassName} onClick={openHelpSidebar}>
                                                {link.name}
                                            </button>
                                        )
                                    }

                                    return (
                                        <Link key={index} href={link.href} className={linkClassName}>
                                            {link.name}
                                        </Link>
                                    )
                                })}
                            </div>

                            <div className="flex items-center gap-6">
                                <MyCabinetButton light={isUseLightColors} variant="dropdown" />

                                {isAuthenticated && pathname === accountPagePath && (
                                    <IconButton
                                        data-cy="toggle-cart-button"
                                        label={resources?.toggleCartIconButtonLabel?.value}
                                        onClick={toggleCart}
                                        className={`p-0 ${
                                            pathname === accountPagePath ? '-order-1' : 'hidden lg:block'
                                        }`}
                                    >
                                        <CartIcon
                                            fill={isUseLightColors ? colors.white : colors.mineshaft}
                                            stroke={isUseLightColors ? colors.white : colors.mineshaft}
                                            badge={totalRXLineItems}
                                        />
                                    </IconButton>
                                )}

                                <JoinCabinetButton light={isUseLightColors} />
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}

function JoinCabinetButton({ light }: { light?: boolean }) {
    const { pathname } = useRouter()
    const { isAuthenticated } = useAuth()
    const isMobileSCreen = useMobileScreenDetection()

    if (pathname === flowPagePath || (pathname === accountPagePath && isMobileSCreen)) {
        return null
    }

    const link = isAuthenticated ? addPrescriptionLink : joinCabinetLink

    return (
        <Link
            className={`h-8 button border-0 flex items-center py-0 text-xbase font-medium ${
                light ? 'text-black bg-white border-white' : 'text-white bg-black'
            }`}
            href={link.href}
        >
            <span className="truncate">{link.name}</span>
        </Link>
    )
}
