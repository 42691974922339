import Modal from 'components/modal/modal'
import { IUiResource } from 'interfaces/ui-resource'
import { colors } from 'theme'

interface IProps {
    cabinetPharmacyCardResource: IUiResource
    onClose: () => void
}

export function CabinetPharmacyCardPopup({ onClose, cabinetPharmacyCardResource }: IProps) {
    const cabinetPharmacyCardImage = cabinetPharmacyCardResource.media

    return (
        <Modal
            closeModal={onClose}
            radiusClassName="rounded-2.5xl"
            crossButtonClassName="!top-5 !right-5 !translate-x-0 !translate-y-0 bg-transparent border"
            crossIconStroke={colors.mineshaft}
        >
            <picture>
                <source type="image/webp" srcSet={cabinetPharmacyCardImage.url + '?fm=webp&w=1200'} />
                <source type="image/png" srcSet={cabinetPharmacyCardImage.url + '?fm=png&w=1200'} />
                <img
                    src={cabinetPharmacyCardResource.media.url}
                    alt={cabinetPharmacyCardResource.value}
                    height={cabinetPharmacyCardImage.height}
                    width={cabinetPharmacyCardImage.width}
                    loading="lazy"
                    data-cy="cabinet-pharmacy-card"
                />
            </picture>
        </Modal>
    )
}
