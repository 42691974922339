import * as React from 'react'

interface IProps {
    firstOptionLabel?: string
    secondOptionLabel?: string | React.ReactNode
    onFirstOptionSelected?: () => void // can be from user action or autoswitch
    onSecondOptionSelected?: () => void // can be from user action or autoswitch
    onUserInteracted?: () => void // user clicked the inputs
    className?: string
    autoSwitch?: boolean
    optionClassName?: string
    toggleClassName?: string
    activeOptionClassName?: string
    selectedOption?: 'first' | 'second'
}

const autoSwitchInterval = 2500

export const Switch = ({
    firstOptionLabel,
    secondOptionLabel,
    onFirstOptionSelected,
    onSecondOptionSelected,
    className = '',
    autoSwitch,
    onUserInteracted,
    optionClassName = '',
    toggleClassName = '',
    activeOptionClassName = '',
    selectedOption,
}: IProps): React.ReactElement => {
    const [activeState, setActiveState] = React.useState<IProps['selectedOption']>(selectedOption || 'first')

    React.useEffect(() => {
        setActiveState(selectedOption)
    }, [selectedOption])

    React.useEffect(() => {
        if (!autoSwitch) {
            return
        }

        const timer = setInterval(() => {
            setActiveState((currentActiveState) => {
                if (currentActiveState === 'first') {
                    onSecondOptionSelected?.()
                    return 'second'
                }

                onFirstOptionSelected?.()
                return 'first'
            })
        }, autoSwitchInterval)

        return () => {
            clearTimeout(timer)
        }
    }, [autoSwitch, onFirstOptionSelected, onSecondOptionSelected])

    return (
        <div className={`relative rounded-full overflow-hidden bg-white inline-flex p-px ${className}`}>
            <label
                htmlFor="first-radio-input"
                className={`px-6 py-2.5 cursor-pointer transition-colors duration-300 relative z-10 ${
                    activeState === 'first' ? `text-white ${activeOptionClassName}` : ''
                } ${optionClassName}`}
            >
                {firstOptionLabel}
            </label>
            <input
                type="radio"
                id="first-radio-input"
                name="toggle"
                className="appearance-none"
                onChange={(e) => {
                    if (e.target.checked) {
                        setActiveState('first')
                        onFirstOptionSelected?.()
                        onUserInteracted?.()
                    }
                }}
                checked={activeState === 'first'}
            />

            <label
                htmlFor="second-radio-input"
                className={`px-6 py-2.5 cursor-pointer transition-colors duration-300 relative z-10 ${
                    activeState === 'second' ? `text-white ${activeOptionClassName}` : ''
                } ${optionClassName} `}
            >
                {secondOptionLabel}
            </label>
            <input
                type="radio"
                id="second-radio-input"
                name="toggle"
                className="appearance-none"
                onChange={(e) => {
                    if (e.target.checked) {
                        setActiveState('second')
                        onSecondOptionSelected?.()
                        onUserInteracted?.()
                    }
                }}
                checked={activeState === 'second'}
            />

            {/* toggle switch */}
            <div
                className={`absolute w-[calc(50%-1px)] top-px h-[calc(100%-2px)] bg-black rounded-full ${
                    activeState === 'first' ? 'translate-x-0' : 'translate-x-full'
                } transition-transform duration-300 ${toggleClassName}`}
            />
        </div>
    )
}
