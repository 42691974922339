import * as React from 'react'
import { IProps as ICheckboxProps } from './checkbox'
import { CheckboxSimple } from './checkbox-simple'

interface IProps extends Omit<ICheckboxProps, 'variant'> {
    labelClassName?: string
    checkedBackgroundColor?: string
}

export function CheckboxWithLabel({
    label,
    className,
    labelClassName,
    checkMarkClassName,
    disabled,
    checkedBackgroundColor = '#fff',
    checkedTickMarkColor = '#000',
    ...props
}: IProps & React.ComponentPropsWithoutRef<'input'>): React.ReactElement {
    return (
        <label
            data-cy="checkbox-label"
            className={`flex flex-row-reverse items-start justify-end cursor-pointer rounded-full ${labelClassName} ${
                disabled ? 'text-gray cursor-not-allowed' : ''
            }`}
            style={{
                ['--checked-bg-color' as string]: checkedBackgroundColor,
            }}
        >
            {label}
            <CheckboxSimple
                checkedTickMarkColor={checkedTickMarkColor}
                checkMarkClassName={checkMarkClassName}
                className={className}
                label={undefined /* undefined because checkbox inside label element */}
                {...props}
            />
        </label>
    )
}
