/*
 * Removes duplicate quer params from the url. A param is duplicate
 * if both the key and value are the same.
 */
export function removeSearchParams({
    paramsToRemove,
    paramString,
}: {
    paramsToRemove: string[]
    paramString: string
}): string {
    if (!paramString?.length) {
        return ''
    }

    if (!paramsToRemove?.length) {
        return paramString
    }

    const searchParams = new URLSearchParams(paramString)
    paramsToRemove.forEach((key) => searchParams.delete(key))

    return searchParams.toString()
}
