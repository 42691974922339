/* eslint-disable react/display-name */
import * as React from 'react'
import Button, { PlainButton } from 'components/buttons/button'
import styles from './style.module.css'
import CabinetSquareLogo from 'icons/cabinet-square-logo'
import { useAuth } from './auth'
import ContentfulRichText from 'components/rich-text'
import { BLOCKS } from '@contentful/rich-text-types'
import { BlankLayout } from 'components/layout'
import globalManifest from 'data/global-manifest.json'
import { accountPagePath } from 'constants/url'

const { commonCopy, resources: uiResources } = globalManifest

const renderOptions = {
    renderNode: {
        [BLOCKS.HEADING_3]: (_node, children) => <h3 className="mt-6 text-3xl">{children}</h3>,
        [BLOCKS.PARAGRAPH]: (_node, children) => <div className="text-base mt-6 leading-140%">{children}</div>,
    },
}

const EmailVerification = (): React.ReactElement => {
    const { loginWithRedirect, logout } = useAuth()
    return (
        <BlankLayout>
            <div data-cy="email-verification-section" className="w-full h-full flex items-center justify-center">
                <div className={`rounded-md p-12 bg-white text-center shadow-cabinet-auth ${styles['panel']}`}>
                    <CabinetSquareLogo className="mx-auto" />
                    <ContentfulRichText
                        // @ts-ignore
                        content={commonCopy.validateEmailMessage?.value?.json}
                        renderOptions={renderOptions}
                    />
                    <Button
                        className="mt-8 px-8"
                        onClick={() => loginWithRedirect({ appState: { returnTo: accountPagePath } })}
                    >
                        {uiResources?.emailVerificationPageViewCabinetButton?.value}
                    </Button>
                    <PlainButton className="mt-8 px-8" onClick={() => logout()}>
                        {uiResources?.clearEmailVerificationModalMsg?.value}
                    </PlainButton>
                </div>
            </div>
        </BlankLayout>
    )
}

export default EmailVerification
