import * as React from 'react'
import { useUserContext } from './user-context'
import { useAuth } from 'components/auth/auth'
import { IProfile } from 'interfaces/profile'
import { fetchCustomer } from '@/lib/api/customer'
import globalManifest from 'data/global-manifest.json'

interface IProps {
    children: React.ReactNode
}

type TProfileContext = {
    profiles: IProfile[]
    activeProfile: IProfile
    setActiveProfile: React.Dispatch<React.SetStateAction<IProfile | null>>
    defaultProfile: IProfile
    profileLoadingStatus: 'idle' | 'loading' | 'error'
}

const ProfileContext = React.createContext<TProfileContext>(null)

const { resources: globalUIResources } = globalManifest

const defaultProfile = {
    id: 'all',
    first_name: globalUIResources['profileSelectorAllAccountsLabel'].value,
    last_name: '',
    date_of_birth: null,
}

export function ProfileContextProvider({ children }: IProps) {
    const { idToken } = useAuth()
    const { user } = useUserContext()

    const [activeProfile, setActiveProfile] = React.useState<IProfile>(defaultProfile)
    const [profiles, setProfiles] = React.useState<IProfile[]>([])
    const [profileLoadingStatus, setProfileLoadingStatus] =
        React.useState<TProfileContext['profileLoadingStatus']>('idle')

    React.useEffect(() => {
        if (!user.id || !idToken) {
            return
        }

        try {
            ;(async () => {
                setProfileLoadingStatus('loading')
                const { data, error } = await fetchCustomer(idToken, user.id)

                if (error) {
                    setProfileLoadingStatus('error')
                    return
                }

                const primaryProfile: IProfile = {
                    id: data.id,
                    first_name: data.first_name,
                    last_name: data.last_name,
                    isPrimary: true,
                }

                // create a profiles array that has all family accounts including the primary account
                let profiles = [primaryProfile]
                profiles = profiles.concat(data.family_accounts)

                setProfiles(profiles)

                if (profiles.length === 1) {
                    setActiveProfile(profiles[0])
                }
                setProfileLoadingStatus('idle')
            })()
        } catch {
            setProfileLoadingStatus('error')
        }
    }, [user.id, idToken])

    const value = React.useMemo(() => {
        return {
            activeProfile,
            setActiveProfile,
            profiles,
            defaultProfile,
            profileLoadingStatus,
        }
    }, [activeProfile, setActiveProfile, profiles, profileLoadingStatus])

    return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>
}

export function useProfileContext(): TProfileContext {
    const context = React.useContext(ProfileContext)

    if (context === null) {
        throw Error('useProfileContext cannot be used outside of <ProfileContextProvider />')
    }

    return context
}
