import * as React from 'react'
import ArrowRightIcon from 'icons/arrow-right'
import { colors } from 'theme'
import { ACTIVE_CART_STEP, useRXCartContext } from 'context/rx-cart-context'
import { useUserContext } from 'context/user-context'
import { CartSummary } from './cart-summary'
import { CartConsents } from './cart-consents'
import { CartPayment } from './cart-payment'
import { useCartSidebarContext } from 'context/cart-sidebar-context'
import globalManifest from 'data/global-manifest.json'
import { ILineItemPrescription } from 'interfaces/order/order'

const { resources: globalUIResources } = globalManifest

export function Cart() {
    const { closeSidebar: closeCart } = useCartSidebarContext()
    const { activeStep, lineItems, gotoSummaryStep, gotoConsentsStep, order, removeLineItem } = useRXCartContext()
    const { createOrder } = order
    const { user } = useUserContext()
    const { open } = useCartSidebarContext()

    const createOrderCb = React.useCallback(() => {
        if (!user?.email) {
            return
        }

        createOrder({
            customer_email: user.email,
            requested_prescriptions: lineItems.map((lineItem) => {
                return {
                    prescription_id: lineItem.id,
                    quantity: lineItem.requestedQuantity,
                    acknowledge_allow_stack_fills: lineItem.isStackingFillsConsented,
                }
            }),
            // for now we assume this is true and only
            // allow user to click on the checkout button if consented
            // else we'd have to generate an orderDetails everytime these consents change
            acknowledge_not_crc_agreement: true,
            authorization_for_use_and_disclosure_agreement: true,
            acknowledge_allow_stack_fills: lineItems.some((lineItem) => lineItem.isStackingFillsConsented),
        })
    }, [createOrder, lineItems, user.email])

    React.useEffect(() => {
        createOrderCb()
    }, [createOrderCb])

    React.useEffect(() => {
        // go to first step when cart is closed
        if (!open) {
            gotoSummaryStep()
        }
    }, [open, gotoSummaryStep])

    const stepsInOrder = [ACTIVE_CART_STEP.SUMMARY, ACTIVE_CART_STEP.CONSENTS, ACTIVE_CART_STEP.PAYMENT]
    const indexOfActiveStep = stepsInOrder.indexOf(activeStep)

    function gotoPreviousStep() {
        switch (indexOfActiveStep) {
            case 1:
                gotoSummaryStep()
                break
            case 2:
                gotoConsentsStep()
        }
    }

    return (
        <div className="h-0 flex-grow flex flex-col">
            <div className="h-full overflow-y-auto grid grid-rows-[auto,1fr]">
                <div>
                    {indexOfActiveStep > 0 && (
                        <div className="px-5 py-2.5 border-b border-gray-medium">
                            <button className="flex items-center gap-x-1" onClick={gotoPreviousStep}>
                                <ArrowRightIcon
                                    className="rotate-180"
                                    strokeWidth={2}
                                    stroke={colors.mineshaft}
                                    height="8"
                                />
                                <span className="text-xs">{globalUIResources['rxCartBackButtonLabel'].value}</span>
                            </button>
                        </div>
                    )}
                </div>
                <div className="grid grid-cols-1 [&>div]:row-start-1 [&>div]:col-start-1 overflow-x-hidden">
                    <SlideOut isVisible={indexOfActiveStep >= 0}>
                        <div className="h-full bg-white">
                            <CartSummary
                                order={order}
                                isCartClearable={true}
                                onCheckout={gotoConsentsStep}
                                onRemove={(lt: ILineItemPrescription) => {
                                    removeLineItem(lt.drug_id)
                                    // if removing the last line item then close empty cart
                                    if (lineItems.length === 1) {
                                        closeCart()
                                    }
                                }}
                                retry={createOrderCb}
                                isHideCabinetSystem
                            />
                        </div>
                    </SlideOut>
                    <SlideOut isVisible={indexOfActiveStep >= 1}>
                        <div className="h-full bg-white">
                            <CartConsents />
                        </div>
                    </SlideOut>
                    <SlideOut isVisible={indexOfActiveStep >= 2}>
                        <div className="h-full bg-white">
                            <CartPayment />
                        </div>
                    </SlideOut>
                </div>
            </div>
        </div>
    )
}

function SlideOut({ children, isVisible }) {
    const hiddenClassName = 'translate-x-full'
    const visibleClassName = 'translate-x-0'

    let className = 'transition-transform'
    className += ' ' + (isVisible ? visibleClassName : hiddenClassName)

    return <div className={className}>{children}</div>
}
