import * as React from 'react'
import Sidebar from 'components/sidebar'
import { useMobileNavSidebarContext } from 'context/mobile-nav-sidebar-context'
import { DIRECTION_HORIZONTAL } from 'enum/direction'
import { SIZE } from 'enum/size'
import globalManifest from 'data/global-manifest.json'
import Link from 'next/link'
import CartIcon from 'icons/cart-icon'
import { MyCabinetButton } from 'components/buttons/my-cabinet'
import {
    navigationAddPrescriptionLinkInternalName,
    navigationJoinCabinetLinkInternalName,
    navigationHelpLinkInternalName,
} from 'constants/contentful'
import { useAuth } from 'components/auth/auth'
import { useHelpSidebar } from 'context/help-sidebar-context'
import { accountPagePath } from 'constants/url'
import { useRouter } from 'next/router'
import CrossIcon from 'icons/cross-icon'
import { IconButton } from 'components/buttons/button'
import { useRXCartContext } from 'context/rx-cart-context'
import { useCartSidebarContext } from 'context/cart-sidebar-context'

const { resources: globalUIResources, mobileNavSidebarLinks } = globalManifest

const linkClassName = 'text-lg leading-140% -tracking-9 w-max text-left'

const mobileNavTopLinks = mobileNavSidebarLinks.linksCollection.items.filter(
    (link) =>
        [navigationJoinCabinetLinkInternalName, navigationAddPrescriptionLinkInternalName].includes(
            link.internalName,
        ) === false,
)

const mobileNavAddPrescriptionLink = mobileNavSidebarLinks.linksCollection.items.find(
    (link) => link.internalName === navigationAddPrescriptionLinkInternalName,
)

const mobileNavJoinCabinetLink = mobileNavSidebarLinks.linksCollection.items.find(
    (link) => link.internalName === navigationJoinCabinetLinkInternalName,
)

export function MobileNavSidebar(): React.ReactElement {
    const { open: isMobileNavSidebarOpen, closeSidebar: closeMobileNav } = useMobileNavSidebarContext()
    const { open: openHelpSidebar } = useHelpSidebar()
    const { isAuthenticated } = useAuth()
    const { pathname } = useRouter()
    const { totalLineItem: totalRXLineItems } = useRXCartContext()
    const { openSidebar: openCart } = useCartSidebarContext()

    return (
        <Sidebar
            open={isMobileNavSidebarOpen}
            onClose={closeMobileNav}
            side={DIRECTION_HORIZONTAL.LEFT}
            size={SIZE.SMALL}
            title={globalUIResources['mobileMegaMenuSidebarTitle'].value}
            asideClassName="max-w-[max(330px,75%)] !bg-[#f2f3f2]/80 backdrop-blur-[20px] bg-[rgba(242,243,242,0.80)]"
            sidebarHeader={
                <div className="border-b border-b-black/20 w-full -my-[9.5px] py-4">
                    <IconButton label="" onClick={closeMobileNav}>
                        <CrossIcon height="22" width="22" />
                    </IconButton>
                </div>
            }
        >
            <div className="px-5 pt-10 pb-7.5 flex flex-col gap-10 justify-between h-full">
                <div className="flex flex-col gap-10">
                    <ul className="flex flex-col gap-10">
                        {mobileNavTopLinks.map((link, index) => {
                            if (link.internalName === navigationHelpLinkInternalName) {
                                if (pathname !== accountPagePath) {
                                    return null
                                }

                                return (
                                    <button
                                        key={index}
                                        className={linkClassName}
                                        onClick={() => {
                                            closeMobileNav()
                                            openHelpSidebar()
                                        }}
                                    >
                                        {link.name}
                                    </button>
                                )
                            }

                            return (
                                <Link key={index} href={link.href} className={linkClassName} onClick={closeMobileNav}>
                                    {link.name}
                                </Link>
                            )
                        })}
                    </ul>
                    <hr className="border-t border-gray" />

                    {/* cart, profile */}
                    <div className="flex flex-col gap-10">
                        {isAuthenticated && pathname === accountPagePath && (
                            <button
                                className="flex items-center gap-2"
                                onClick={() => {
                                    openCart()
                                    closeMobileNav()
                                }}
                            >
                                {/* make contianer for cart icon big as the icon below and this one are off in size and look off in screen */}
                                <div className="w-[20px] flex justify-center">
                                    <CartIcon badge={totalRXLineItems} />
                                </div>
                                <span className={`${linkClassName} leading-[1]`}>
                                    {globalUIResources['mobileSidebarCartLabel'].value}
                                </span>{' '}
                            </button>
                        )}

                        <MyCabinetButton isShowLabel variant="list" />
                    </div>
                </div>

                {!isAuthenticated && mobileNavJoinCabinetLink && (
                    <Link
                        onClick={closeMobileNav}
                        href={mobileNavJoinCabinetLink.href}
                        className="button button-primary button-bloated"
                    >
                        {mobileNavJoinCabinetLink.name}
                    </Link>
                )}

                {isAuthenticated && mobileNavAddPrescriptionLink && (
                    <Link
                        onClick={closeMobileNav}
                        href={mobileNavAddPrescriptionLink.href}
                        className="button button-primary button-bloated"
                    >
                        {mobileNavAddPrescriptionLink.name}
                    </Link>
                )}
            </div>
        </Sidebar>
    )
}
