import ProfileIcon from 'icons/profile-icon'
import { colors } from 'theme.cjs'
import globalManifest from 'data/global-manifest.json'
import { accountPagePath, flowPagePath } from 'constants/url'
import { ProfileSelector, IProps as IProfileSelectorProps } from 'components/profile-selector'
import Link from 'next/link'
import { useRouter } from 'next/router'
import useMobileScreenDetection from 'hooks/useMobileScreenDetection'

const { resources: globalUIResources } = globalManifest

interface IProps {
    light?: boolean
    isShowLabel?: boolean // current use case: mobile sidebar shows lables
    variant: IProfileSelectorProps['variant']
}

const buttonClassName = 'flex gap-2 p-0 items-center'
const buttonLabelClassName = 'text-lg leading-[1] -tracking-9 font-medium mt-px'

/**
 * This button lives in the header and sidebar
 */
export const MyCabinetButton = ({ light, isShowLabel, variant }: IProps): React.ReactElement => {
    const { pathname } = useRouter()
    const isMobileScreen = useMobileScreenDetection()

    if (pathname === flowPagePath || (pathname !== accountPagePath && !isMobileScreen)) {
        return (
            <Link href={accountPagePath} className={buttonClassName} data-cy="account-link">
                <ProfileIcon fill={light ? colors.white : colors.mineshaft} />
                {isShowLabel && <span className={buttonLabelClassName}>{globalUIResources['accountLabel'].value}</span>}
            </Link>
        )
    }

    return <ProfileSelector variant={variant} />
}
