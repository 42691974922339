import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'
import React from 'react'
import { pageBackgroundClassname } from 'theme.cjs'
import { HeaderNav } from 'components/navigation/header-navbar'
import PreviewAlert from 'components/preview-alert'
import Footer from './navigation/footer'
import { Banner } from './banner'

interface ILayoutProps {
    // preview flag is used in API calls to Contentful to fetch unpublished content
    preview?: boolean
    children: React.ReactNode
}

export default function Layout({ preview = false, children }: ILayoutProps): React.ReactElement {
    return (
        <>
            <Banner />
            <HeaderNav />
            <div id="min-height-layout" className={`min-h-screen ${pageBackgroundClassname}`}>
                {preview && <PreviewAlert />}
                <main>{children}</main>
            </div>
            <Footer />
        </>
    )
}

export function BlankLayout({ preview = false, children }: ILayoutProps): React.ReactElement {
    useIsomorphicLayoutEffect(() => {
        // set body height to 100% only in blank layout, 100% height
        // breaks margin-bottom logic to add empty space for fixed elements
        // ** we can't apply global styles from specific pages/components
        // so we need to use this approach
        document.body.classList.add('h-full')
    }, [])

    return (
        // use section, there's a bug using div here
        <section className="h-full w-full">
            {preview && <PreviewAlert />}
            {children}
        </section>
    )
}

export function LayoutWithoutFooter({ preview = false, children }: ILayoutProps): React.ReactElement {
    return (
        <>
            <Banner />
            <HeaderNav />
            <div className={`${pageBackgroundClassname}`}>
                {preview && <PreviewAlert />}
                <main>{children}</main>
            </div>
        </>
    )
}
