import { fetchBackend } from './backend'

/**
 * Sends events to the backend for analytics and for dispatching of events
 * to 3rd party pixels
 * @param eventName
 * @param eventProperties
 */
export const track = async (eventName: string, eventProperties: any) => {
    try {
        const response = await fetchBackend('/api/event/create', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                name: eventName,
                data: eventProperties,
                session: {},
            }),
        })
        if (!response.ok) {
            console.error(`HTTP error! status: ${response.status}`)
        }
    } catch (error) {
        console.error('Error tracking event:', error)
    }
}

export const identify = async (userId: string, userProperties?: Record<string, string>) => {
    try {
        const response = await fetchBackend('/api/customer/identify', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: userId, userProperties }),
        })
        if (!response.ok) {
            console.error(`HTTP error! status: ${response.status}`)
        }
    } catch (error) {
        console.error('Error identifying user:', error)
    }
}
