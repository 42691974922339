import * as React from 'react'
import { colors } from 'theme.cjs'

const COLOR_SEPARATOR = '__'

// pattern example: __red__text in red__, __#ffffff__textinwhite__
// forms following regexp /__[a-zA-Z0-9\W]*__[a-zA-Z0-9\W]*__/g
const COLOR_CODE_REGEXP_IDENTIFIER = new RegExp(
    `${COLOR_SEPARATOR}[a-zA-Z0-9\\W]*${COLOR_SEPARATOR}[a-zA-Z0-9\\W]*${COLOR_SEPARATOR}`,
    'g',
)

const SUPERSCRIPT_REGEXP_IDENTIFIER = /<sup>[^<>]*<\/sup>/g

const getColor = (colorCode: string) => {
    if (Object.keys(colors).includes(colorCode)) {
        return colors[colorCode]
    }

    return colorCode
}

/**
 * Replaces \n with <br />
 * @param text string
 * @returns (string | React.ReactElement)[]
 */
export const parseLineBreaks = (text: string): (string | React.ReactElement)[] => {
    return text.split('\n').reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment]
    }, [])
}

/**
 * Takes string or react element and traverses through
 * all text nodes and parses for color codes
 * @param text string | (string | React.ReactElement)[]
 * @returns string | (string | React.ReactElement)[]
 */
export const parseColorCodedString = (
    text: string | (string | React.ReactElement)[],
): string | (string | React.ReactElement)[] => {
    // parse for color code if string
    if (typeof text === 'string') {
        // color coded strings
        const colorCodedString = text.match(COLOR_CODE_REGEXP_IDENTIFIER)

        if (!colorCodedString) {
            return text
        }

        // all non color coded string segments
        // can be a word of group of words
        const words = text.split(COLOR_CODE_REGEXP_IDENTIFIER)

        // for every non color coded string there exists
        // a color coded string except the last one
        const parsedText = words?.map((word, i) => {
            const colorCodeStringSplit = colorCodedString?.[i]?.split(COLOR_SEPARATOR)
            const colorCode = colorCodeStringSplit?.[1]
            const coloredWord = colorCodeStringSplit?.[2]

            return (
                <span key={words[i] + i + 'color-parse'}>
                    {word}
                    {coloredWord && <span style={{ color: getColor(colorCode) }}>{coloredWord}</span>}
                </span>
            )
        })

        return parsedText
    }

    // if React element then traverse upto the text node
    // note: text nodes will only be two levels deep at max
    return React.Children.map(text, (child) => {
        // call parse function recursively when text
        // node found
        if (!React.isValidElement(child)) {
            // @ts-ignore
            return parseColorCodedString(child)
        }

        // if element has no children then just render
        // the element
        // @ts-ignore
        if (!React.Children.count(child.props.children)) {
            return child
        }

        // parse through the children of child to find text nodes
        // @ts-ignore
        return React.Children.map(child.props.children, (c) => {
            // just return element if valid element
            if (React.isValidElement(c)) {
                return c
            }

            // call parse function on text recursively
            return parseColorCodedString(c)
        })
    })
}

/**
 * Takes string or react element and traverses through
 * all text nodes and parses for <sup> tag, logic similar
 * to parseColorCodedString function
 * @param text string | (string | React.ReactElement)[]
 * @returns string | (string | React.ReactElement)[]
 */
export const parseSuperscript = (
    text: string | (string | React.ReactElement)[],
): string | (string | React.ReactElement)[] => {
    if (typeof text === 'string') {
        const superscripts = text.match(SUPERSCRIPT_REGEXP_IDENTIFIER)

        if (!superscripts) {
            return text
        }

        const unmatchedWords = text.split(SUPERSCRIPT_REGEXP_IDENTIFIER)

        return unmatchedWords.map((word, i) => (
            <React.Fragment key={word + i + 'superscript'}>
                {word}
                {superscripts[i] && <sup>{superscripts[i].replace('<sup>', '').replace('</sup>', '')} </sup>}
            </React.Fragment>
        ))
    }

    return React.Children.map(text, (child) => {
        if (!React.isValidElement(child)) {
            //@ts-ignore
            return parseSuperscript(child)
        }

        //@ts-ignore
        if (!React.Children.count(child.props.children)) {
            return child
        }
        //@ts-ignore
        return React.Children.map(child.props.children, (c) => {
            if (React.isValidElement(c)) {
                return c
            }

            return parseSuperscript(c)
        })
    })
}
