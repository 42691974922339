import * as React from 'react'
import { Overlay } from 'components/overlay'
import { useProfileContext } from 'context/profile-context'
import { useMobileNavSidebarContext } from 'context/mobile-nav-sidebar-context'
import { useRouter } from 'next/router'
import { accountPagePath } from 'constants/url'
import CaretIcon from 'icons/caret-icon'
import ProfileIcon from 'icons/profile-icon'
import Link from 'next/link'
import globalManifest from 'data/global-manifest.json'

export interface IProps {
    variant: 'list' | 'dropdown'
}

const { resources: globalUIResources } = globalManifest

export function ProfileSelector({ variant }: IProps) {
    const { profileLoadingStatus } = useProfileContext()

    if (profileLoadingStatus !== 'idle') {
        return null
    }

    if (variant === 'dropdown') {
        return <ProfileSelectorDropdown />
    }

    return <ProfileSelectorList />
}

const selectorClassName =
    'px-3 py-2.5 text-xbase leading-4 -tracking-11 border rounded-full max-w-24 text-center truncate content-box'
const dropdownSelectorClassName =
    'w-full px-5 py-4 text-left text-sm leading-140% tracking-[0.14px] border-b border-gray-medium last:border-0 hover:bg-gray-lighter truncate'

function ProfileSelectorDropdown() {
    const { profiles, activeProfile, setActiveProfile, defaultProfile } = useProfileContext()

    const [isShowDropdown, setIsShowDropdown] = React.useState(false)

    if (profiles.length <= 1) {
        return null
    }

    return (
        <div className="relative hidden lg:block" data-cy="profile-selector">
            <button
                className={`${selectorClassName} text-left flex space-x-1 items-center !py-1.5`}
                onClick={() => setIsShowDropdown(!isShowDropdown)}
            >
                <span className="flex-1 truncate">{activeProfile.first_name}</span>{' '}
                <span className="text-[8px] text-right items-center">
                    <CaretIcon height={18} width={12} className={isShowDropdown ? 'rotate-180' : ''} />
                </span>
            </button>

            {isShowDropdown && (
                <Overlay className="w-32 !top-11">
                    {activeProfile.id !== defaultProfile.id && (
                        <button
                            className={`${dropdownSelectorClassName} font-bold bg-gray-lighter`}
                            onClick={() => {
                                setActiveProfile(defaultProfile)
                                setIsShowDropdown(false)
                            }}
                        >
                            {defaultProfile.first_name}
                        </button>
                    )}
                    {profiles.map((p) => {
                        if (p.id === activeProfile.id) {
                            return null
                        }
                        return (
                            <button
                                key={p.id}
                                className={dropdownSelectorClassName}
                                onClick={() => {
                                    setActiveProfile(p)
                                    setIsShowDropdown(false)
                                }}
                            >
                                {p.first_name}
                            </button>
                        )
                    })}
                </Overlay>
            )}
        </div>
    )
}

const buttonClassName = `px-3 py-2.5 rounded-full text-xbase leading-140% -tracking-9 ring-1 ring-black hover:ring-2`
const buttonActiveClassName = 'ring-2'

function ProfileSelectorList() {
    const { profiles, activeProfile, setActiveProfile, defaultProfile } = useProfileContext()
    const { closeSidebar } = useMobileNavSidebarContext()
    const { pathname } = useRouter()

    if (pathname !== accountPagePath) {
        return (
            <Link href="/account" className="flex gap-2 items-center" onClick={closeSidebar} data-cy="account-link">
                <ProfileIcon />
                <span className="text-lg leading-[1] -tracking-9">{globalUIResources['accountLabel'].value}</span>
            </Link>
        )
    }

    if (profiles.length <= 1) {
        return null
    }

    return (
        <ul className="flex gap-3 flex-wrap">
            <li>
                <button
                    className={`${buttonClassName}
                        ${activeProfile.id === defaultProfile.id ? buttonActiveClassName : ''}
                    `}
                    onClick={() => {
                        setActiveProfile(defaultProfile)
                        closeSidebar()
                    }}
                >
                    {defaultProfile.first_name}
                </button>
            </li>

            {profiles.map((p) => {
                return (
                    <li key={p.id}>
                        <button
                            className={`${buttonClassName} 
                              ${activeProfile.id === p.id ? buttonActiveClassName : ''}
                            `}
                            onClick={() => {
                                setActiveProfile(p)
                                closeSidebar()
                            }}
                        >
                            {p.first_name}
                        </button>
                    </li>
                )
            })}
        </ul>
    )
}
