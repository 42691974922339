import * as React from 'react'

const TickMarkWideIcon = ({
    strokeWidth = 2,
    stroke = '#6FCF97',
    className = '',
    height = 17,
    width = 12,
} = {}): React.ReactElement => (
    <svg
        className={className}
        width={width}
        height={height}
        viewBox="0 0 17 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M1 6L6 11L16 1"
            stroke={stroke}
            strokeWidth={strokeWidth}
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
)

export default TickMarkWideIcon
