import * as React from 'react'

interface IArgs {
    element: HTMLElement
    options?: IntersectionObserverInit
}

/**
 * This hook observers an element
 * @param Arguments IArgs
 * @returns boolean
 */
const useIntersectionObserver = ({
    element,
    options,
}: IArgs): { entry: IntersectionObserverEntry; observer: IntersectionObserver } => {
    const [entry, setEntry] = React.useState<IntersectionObserverEntry>()
    const [observer, setObserver] = React.useState<IntersectionObserver>()

    React.useEffect(() => {
        if (!element) {
            return
        }

        // exit effect when IntersectionObserver is not supported
        if (!('IntersectionObserver' in window)) {
            console.warn('IntersectionObserver is not supported')
            return
        }

        const intersectionCallback = ([entry]) => {
            if (entry.target === element) {
                setEntry(entry)
            }
        }

        // define intersection observer
        // intersection observer is not supported in IE
        // need to implement polyfill for it
        // polyfill: https://github.com/w3c/IntersectionObserver
        const io = new IntersectionObserver(intersectionCallback, options)

        // Start observing element
        io.observe(element)

        setObserver(io)

        return () => {
            io.disconnect()
        }
    }, [element, options])

    return { entry, observer }
}

export default useIntersectionObserver
