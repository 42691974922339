import { CHECKOUT_SOURCE, CheckoutForm } from 'components/checkout/checkout-form'
import React from 'react'
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import lightTwConfig from 'tailwind-light-config'

interface IProps {
    totalPrice: number
    clientSecret: string
    onSubmitButtonClick?: () => void
    onPaymentFailure?: () => void
    source: CHECKOUT_SOURCE
    customerName?: string
}

const { colors } = lightTwConfig

// Make sure to call load Stripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
const stripePromise = loadStripe(process.env.STRIPE_PUBLISHABLE_KEY)

export function StripeCheckout({
    totalPrice,
    clientSecret,
    onSubmitButtonClick,
    onPaymentFailure,
    source,
    customerName,
}: IProps) {
    const options: StripeElementsOptions = {
        clientSecret,
        appearance: {
            theme: 'flat',
            variables: {
                colorPrimary: colors.black.DEFAULT,
                colorTextSecondary: colors.gray.darker,
                colorBackground: colors.gray.lighter,
                borderRadius: '12px',
            },
        },
    }

    return (
        <div className="stripe-checkout">
            {clientSecret && (
                // key prop necessary here so Stripe form re-mounts, updates to clientSecret
                // is not permitted thus a remount is necessary
                <Elements options={options} stripe={stripePromise} key={clientSecret}>
                    <CheckoutForm
                        totalPrice={totalPrice}
                        clientSecret={clientSecret}
                        onSubmitButtonClick={onSubmitButtonClick}
                        onPaymentFailure={onPaymentFailure}
                        source={source}
                        customerName={customerName}
                    />
                </Elements>
            )}
        </div>
    )
}
