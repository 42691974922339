import { IFeatures } from 'interfaces/features'
import { fetchWithTimeout } from 'lib/util/fetch'
import React from 'react'

interface IGrowthBookFeaturesContext {
    isFetchingFeatures: boolean
    features: IFeatures
    error: any
}

interface IProps {
    children: React.ReactNode
}

const GrowthBookFeaturesContext = React.createContext<IGrowthBookFeaturesContext>(null)

export const GrowthbookFeaturesContextProvider = ({ children }: IProps): React.ReactElement => {
    const [isFetchingFeatures, setIsFetchingFeatures] = React.useState(false)
    const [features, setFeatures] = React.useState<IFeatures>()
    const [error, setError] = React.useState()

    React.useEffect(() => {
        setIsFetchingFeatures(true)
        fetchWithTimeout(`https://cdn.growthbook.io/api/features/${process.env.GROWTHBOOK_FEATURES_API_KEY}`)
            .then((res) => res.json())
            .then((parsed) => {
                setFeatures(parsed.features)
            })
            .catch((e) => {
                setFeatures(null)
                setError(e)
            })
            .finally(() => setIsFetchingFeatures(false))
    }, [])

    const value = React.useMemo(
        () => ({
            isFetchingFeatures,
            error,
            features,
        }),
        [isFetchingFeatures, error, features],
    )

    return <GrowthBookFeaturesContext.Provider value={value}>{children}</GrowthBookFeaturesContext.Provider>
}

export const useGrowthbookFeaturesContext = (): IGrowthBookFeaturesContext => {
    const growthbookFeaturesContext = React.useContext(GrowthBookFeaturesContext)

    if (typeof growthbookFeaturesContext === 'undefined') {
        throw new Error('You cannot use useGrowthbookFeaturesContext out side of GrowthbookFeaturesContextProvider')
    }

    return growthbookFeaturesContext
}
