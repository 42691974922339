import * as React from 'react'
import { IPrescriptionLineItem } from 'interfaces/prescription-line-item'
import { useOrder, IReturn as IUseOrderReturn } from 'hooks/useOrder'

export enum ACTIVE_CART_STEP {
    SUMMARY = 'summary',
    CONSENTS = 'consents',
    PAYMENT = 'payment',
}
export interface IRXCartContext {
    lineItems: IPrescriptionLineItem[]
    addLineItem: (lineItem: IPrescriptionLineItem) => void
    removeLineItem: (lineItemId: string) => void
    totalLineItem: number
    replaceLineItem: (lineItem: IPrescriptionLineItem) => void
    lineItemToHighlightId: string
    setLineItemToHighlightId: React.Dispatch<React.SetStateAction<string>>
    activeStep: ACTIVE_CART_STEP
    isCheckoutDisabled: boolean
    gotoSummaryStep: () => void
    gotoConsentsStep: () => void
    gotoPaymentStep: () => void
}

type TRXCartContext = IRXCartContext & { order: IUseOrderReturn }

interface IProps {
    children: React.ReactNode
}

const RXCartContext = React.createContext<TRXCartContext>(null)

const RXCartContextProvider = ({ children }: IProps): React.ReactElement => {
    const order = useOrder()
    const [lineItems, setLineItems] = React.useState<IPrescriptionLineItem[]>([])

    const [lineItemToHighlightId, setLineItemToHighlightId] = React.useState<string>()
    const [activeStep, setActiveStep] = React.useState<ACTIVE_CART_STEP>(ACTIVE_CART_STEP.SUMMARY)

    const addLineItem = React.useCallback((lineItem: IPrescriptionLineItem) => {
        setLineItems((lineItems) => [...lineItems, lineItem])
    }, [])

    const removeLineItem = React.useCallback((drugId: string) => {
        setLineItems((lineItems) => lineItems.filter((lineItem) => lineItem.drug.id !== drugId))
    }, [])

    const replaceLineItem = React.useCallback((lineItem: IPrescriptionLineItem) => {
        setLineItems((lineItems) => {
            const existingLineItemIndex = lineItems.findIndex((lt) => lt.id === lineItem.id)

            if (existingLineItemIndex === -1) {
                return lineItems
            }

            const clonedLineItems = [...lineItems]
            clonedLineItems[existingLineItemIndex] = lineItem

            return clonedLineItems
        })
    }, [])

    React.useEffect(() => {
        if (!lineItemToHighlightId) {
            return
        }

        const timer = setTimeout(() => {
            setLineItemToHighlightId(null)
        }, 2500)

        return () => {
            clearTimeout(timer)
        }
    }, [lineItemToHighlightId])

    const value: TRXCartContext = React.useMemo(() => {
        function gotoSummaryStep() {
            setActiveStep(ACTIVE_CART_STEP.SUMMARY)
        }
        function gotoConsentsStep() {
            setActiveStep(ACTIVE_CART_STEP.CONSENTS)
        }

        function gotoPaymentStep() {
            setActiveStep(ACTIVE_CART_STEP.PAYMENT)
        }

        return {
            lineItems,
            addLineItem,
            removeLineItem,
            totalLineItem: lineItems.length,
            activeStep,
            replaceLineItem,
            lineItemToHighlightId,
            setLineItemToHighlightId,
            isCheckoutDisabled: order?.orderCreationState === 'pending' || !order?.stripe_client_secret,
            gotoSummaryStep,
            gotoConsentsStep,
            gotoPaymentStep,
            order,
        }
    }, [lineItems, addLineItem, removeLineItem, order, replaceLineItem, lineItemToHighlightId, activeStep])

    return <RXCartContext.Provider value={value}>{children}</RXCartContext.Provider>
}

export const useRXCartContext = (): TRXCartContext => {
    const rxCartContext = React.useContext(RXCartContext)

    if (rxCartContext === undefined) {
        throw new Error('You cannot use useRXCartContext outside of RXCartContextProvider')
    }

    return rxCartContext
}

export default RXCartContextProvider
