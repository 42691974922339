import * as Sentry from '@sentry/nextjs'
import { StripeAddressElementOptions, StripePaymentElementOptions } from '@stripe/stripe-js'
import Button from 'components/buttons/button'
import React, { useEffect, useState } from 'react'
import { PaymentElement, useStripe, useElements, AddressElement } from '@stripe/react-stripe-js'
import { postPaymentFlowUrl } from 'constants/url'
import useToastContext from 'context/toast-context'
import globalManifest from 'data/global-manifest.json'
import { removeSearchParams } from '@/lib/util/url'

export enum CHECKOUT_SOURCE {
    FLOW = 'flow',
    CART = 'cart',
}

interface IProps {
    totalPrice: number
    clientSecret: string
    onSubmitButtonClick?: () => void
    onPaymentFailure?: () => void
    source: CHECKOUT_SOURCE
    customerName?: string
}

const stripeQueryParams = ['payment_intent_client_secret', 'payment_intent', 'redirect_status']

const { resources: globalUIResources } = globalManifest

export function CheckoutForm({ totalPrice, clientSecret, onSubmitButtonClick, source, customerName }: IProps) {
    const stripe = useStripe()
    const elements = useElements()
    const { showToast } = useToastContext()

    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        if (!stripe || !clientSecret) {
            return
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ error }) => {
            if (error) {
                showToast({ type: 'error', children: `Something went wrong: ${error.message}` })
            }
        })
    }, [stripe, clientSecret, showToast])

    const handleSubmit = async (e) => {
        e.preventDefault()

        if (!stripe || !elements) {
            // Stripe.js hasn't yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return
        }

        setIsLoading(true)

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url:
                    window.location.origin +
                    postPaymentFlowUrl +
                    '?' +
                    new URLSearchParams(
                        `${removeSearchParams({
                            paramsToRemove: stripeQueryParams,
                            paramString: window.location.search,
                        })}&source=${source}`,
                    ).toString(),
            },
        })

        // This point will only be reached if there is an immediate error when
        // confirming the payment. Otherwise, your customer will be redirected to
        // your `return_url`. For some payment methods like iDEAL, your customer will
        // be redirected to an intermediate site first to authorize the payment, then
        // redirected to the `return_url`.
        if (error) {
            if (error.type === 'card_error' || error.type === 'validation_error') {
                showToast({ type: 'error', children: error.message })
            } else {
                showToast({ type: 'error', children: 'An unexpected error occurred.' })
            }
            Sentry.captureException(`Stripe payment failed: ${error.message}`)
        }

        setIsLoading(false)
    }

    const addressElementShipping: StripeAddressElementOptions = {
        mode: 'shipping',
        allowedCountries: ['US'],
        autocomplete: {
            mode: 'google_maps_api',
            apiKey: process.env.GOOGLE_MAPS_API_KEY!,
        },
        defaultValues: {
            name: customerName,
        },
    }
    const paymentElementOptions: StripePaymentElementOptions = {
        layout: 'accordion',
    }

    // TODO: Prepopulate address and payment if we have it for the customer
    return (
        <form id="payment-form" className="grid gap-4" onSubmit={handleSubmit}>
            <div className="bg-white rounded-xl p-4 border border-[#f2f2f2]">
                <AddressElement options={addressElementShipping} />
            </div>
            <PaymentElement id="payment-element" options={paymentElementOptions} />
            <Button
                id="submit"
                disabled={isLoading || !stripe || !elements}
                loading={isLoading}
                className="w-full"
                onClick={() => {
                    onSubmitButtonClick?.()
                }}
            >
                {`${globalUIResources['payLabel'].value} - $${totalPrice.toFixed(2)}`}
            </Button>
        </form>
    )
}
