import React from 'react'
import { DIRECTION_HORIZONTAL } from 'enum/direction'
import { SIZE } from 'enum/size'
import useIsomorphicLayoutEffect from 'hooks/useIsomorphicLayoutEffect'
import SidebarHeader from './sidebar-header'

interface IProps {
    side?: DIRECTION_HORIZONTAL
    open: boolean
    onClose: () => void
    title?: string
    children?: React.ReactNode
    className?: string
    // unique identfier, it's used to identify the component that's being
    // renderd in the sidebar, this does not affect the UI
    // use case - to get correct element when running tests
    uniqueSidebarIdentifier?: string
    size?: SIZE
    showTitleDivider?: boolean
    // use this prop to render element besides
    // the close sidebar icon
    sidebarHeader?: React.ReactNode
    // applied to <aside />
    asideClassName?: string
    closeButton?: React.ReactNode
}

// todo: time for refactor? this component is getting
// difficult to customize
export default function Sidebar({
    className,
    side = DIRECTION_HORIZONTAL.RIGHT,
    open,
    onClose,
    title,
    children,
    uniqueSidebarIdentifier,
    size = SIZE.BIG,
    showTitleDivider,
    sidebarHeader,
    asideClassName = '',
    closeButton,
}: IProps): React.ReactElement {
    // remove scrollbar from body when sidebar is open
    // and add scrollbar width as padding to prevent
    // layout shift
    useIsomorphicLayoutEffect(() => {
        const bodyEl = document.body

        if (bodyEl && open) {
            // with overlaying scrollbar, window.innerWidth and body.cliendWidth is the same
            const scrollBarWidth = window.innerWidth - bodyEl.clientWidth

            document.documentElement.style.setProperty('--scrollBarGutter', scrollBarWidth + 'px')
            bodyEl.classList.add('no-scroll')

            return () => {
                if (bodyEl) {
                    document.documentElement.style.setProperty('--scrollBarGutter', '0px')
                    bodyEl.classList.remove('no-scroll')
                }
            }
        }
    }, [open])

    const sideClass = side === DIRECTION_HORIZONTAL.RIGHT ? 'right-0' : 'left-0'
    const translateClass = open
        ? 'translate-x-0'
        : side === DIRECTION_HORIZONTAL.RIGHT
        ? 'translate-x-full'
        : '-translate-x-full'

    let widthClass = 'w-full'

    if (size === SIZE.BIG) {
        widthClass += ' md:w-7/12 xl:w-2/5 2xl:w-1/3'
    } else if (size === SIZE.SMALL) {
        widthClass += ' sm:w-1/2 lg:w-1/3 xl:w-1/4 2xl:w-1/5'
    }

    sidebarHeader = sidebarHeader ?? <SidebarHeader title={title} onClose={onClose} closeButton={closeButton} />

    return (
        <div className={className} aria-hidden={!open}>
            <div
                className={`h-full fixed top-0 ${sideClass} bg-gray opacity-30 cursor-pointer z-50 ${
                    open ? 'w-full' : 'w-0'
                }`}
                onClick={onClose}
            ></div>
            <aside
                className={`h-full ${widthClass} flex flex-col fixed top-0 z-50 bg-white transition-all duration-200 ${sideClass} ${translateClass} ${asideClassName}`}
                data-cy={uniqueSidebarIdentifier ? `${uniqueSidebarIdentifier}-sidebar` : 'sidebar'}
            >
                {/* cart sidebar header */}
                {/* 9.5px here is an arbitrary value - 9.5px makes 
                the divider line match with the header line*/}
                <div className="px-5 py-2.5 md:py-[9.5px] flex items-center">{sidebarHeader}</div>

                {showTitleDivider && <hr className="border-gray-medium" />}

                {/* height 0 and flex grow makes the element only grow to available height
                    height needs to be explicitly set here to prevent safari bug where the
                    child of this element would have no height
                */}
                <div className="h-0 grow flex flex-col">{children}</div>
            </aside>
        </div>
    )
}
