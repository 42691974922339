/* eslint-disable react/display-name */
import * as React from 'react'
import Modal from 'components/modal/modal'
import styles from './style.module.css'
import globalManifest from 'data/global-manifest.json'
import ContentfulRichText from 'components/rich-text'
import Button from 'components/buttons/button'
import { BLOCKS } from '@contentful/rich-text-types'
import { IFormInput } from 'interfaces/form-input'
import { validateEmail, validateMobilePhone } from 'lib/util/form-validation'
import useIntersectionObserver from 'hooks/useIntersectionObserver'
import { useEmailCapturePopupContext } from 'context/email-capture-popup-context'
import { IImage } from 'interfaces/media'
import ArrowRightIcon from 'icons/arrow-right'
import { colors } from 'theme.cjs'
import { useCustomerContext } from 'context/customer-context'
import { userSubscribeEventName } from 'misc-variables'
import {
    websiteSubscribeFullScreenExperimentName,
    websiteSubscribeOrderEmailFirstVariation,
    websiteSubscribeOrderExperimentName,
    websiteSubscribeOrderPhoneFirstVariation,
} from 'constants/growthbook'
import twConfig from 'tailwind-light-config'
const { screens } = twConfig
import { useFeature } from '@growthbook/growthbook-react'
import LogoWithTradeMark from 'components/logo-with-trademark'
import useMobileScreenDetection from 'hooks/useMobileScreenDetection'
import { fetchWithTimeout } from '@/lib/util/fetch'
import * as Sentry from '@sentry/nextjs'

const { resources: globalUIResources } = globalManifest

const lgBreakPoint = Number(screens.lg.replace('px', ''))

const headerRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="text-xl font-bold leading-120% tracking-tight text-center">{children}</p>
    ),
}

const headerFullLayoutRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="text-4xl mb-2 lg:mb-0 lg:text-6xl font-bold leading-120% lg:leading-110% tracking-tight text-center">
            {children}
        </p>
    ),
}

const subHeaderRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="text-xs md:text-base font-medium tracking-tight leading-120% text-center">{children}</p>
    ),
}

const subHeaderFullLayoutRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <p className="text-sx lg:text-xl font-medium tracking-tight leading-120% text-center">{children}</p>
    ),
}

const errorMessageRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => children,
}

const successMessageRenderNode = {
    [BLOCKS.HEADING_6]: (_node, children) => (
        <p className="text-3xl md:text-3.25xl text-center mb-6 leading-110%">{children}</p>
    ),
    [BLOCKS.PARAGRAPH]: (_node, children) => <p className="text-center mt-6">{children}</p>,
}

const disclaimerRenderNode = {
    [BLOCKS.PARAGRAPH]: (_node, children) => (
        <span className="text-gray-darker text-xxs leading-140% text-center block">{children}</span>
    ),
}

const subscribeEventName = (subscribeType: string) => `Subscribe Popup - ${userSubscribeEventName} - ${subscribeType}`

const renderPicture = (image: IImage) =>
    image && (
        <picture>
            <source type="image/webp" srcSet={image.url + '?fm=webp'} />
            <source type="image/jpeg" srcSet={image.url + '?fm=jpg'} />
            <img
                src={image.url + '?fm=jpg'}
                alt={image.title || image.description}
                height={image.height}
                width={image.width}
                className="mx-auto"
            />
        </picture>
    )

export const EmailCapturePopup = (): React.ReactElement => {
    const {
        isOpen,
        closePopup,
        openPopup,
        hasBeenDisplayed,
        setHasBeenDisplayed,
        popup,
        isDisabled,
        isShouldNotBeDisplayed,
    } = useEmailCapturePopupContext()
    const { identify, identity, track } = useCustomerContext()

    const [activeInputIndex, setActiveInputIndex] = React.useState(0)
    const [isSubmittingForm, setIsSubmittingForm] = React.useState(false)
    const [formSubmissionStatus, setFormSubmissionStatus] = React.useState<'success' | 'error'>(null)
    const [errorInputIndex, setErrorInputIndex] = React.useState(-1)

    const [markerEl, setMarkerEl] = React.useState<HTMLDivElement>()

    const markerElRef = React.useCallback((node) => {
        if (node !== null) {
            setMarkerEl(node)
        }
    }, [])

    const formRef = React.useRef<HTMLFormElement>()

    const { entry, observer } = useIntersectionObserver({
        element: markerEl,
    })

    // disconnect observer immediately if disabled
    React.useEffect(() => {
        if (isDisabled) {
            observer?.disconnect()
        }
    }, [isDisabled, observer])

    React.useEffect(() => {
        if (entry?.boundingClientRect?.top < 0) {
            openPopup()
        }
    }, [entry?.boundingClientRect?.top, openPopup])

    React.useEffect(() => {
        if (hasBeenDisplayed) {
            observer?.disconnect()
        }
    }, [hasBeenDisplayed, observer])

    const focusInput = (inputIndex: number) => {
        const activeInputElement = formRef.current?.querySelectorAll('input')?.[inputIndex]
        activeInputElement?.focus()
    }

    React.useEffect(() => {
        // focus input except the first
        // activeInputIndex starts from 0
        if (activeInputIndex) {
            focusInput(activeInputIndex)
        }
    }, [activeInputIndex])

    const { form, brandIcons } = popup ?? {}

    const {
        header,
        subHeader,
        submitButtonText,
        action,
        media,
        mobileMedia,
        errorMessage,
        successMessage,
        paragraph,
        inputsCollection,
        disclaimer,
        siteLocation,
    } = form ?? {}

    const defaultFormInputs = inputsCollection?.items

    // experiment with the order of subscribe (sms first vs. email first)
    const defaultEmailInputIndex = defaultFormInputs.findIndex((input) => input.name.includes('email'))
    const defaultPhoneInputIndex = defaultFormInputs.findIndex((input) => input.name.includes('phone'))
    const subscribeOrderExperiment = useFeature(websiteSubscribeOrderExperimentName)
    // Default to email first
    const subscribeOrdering = subscribeOrderExperiment?.value || websiteSubscribeOrderEmailFirstVariation
    let phoneInputIndex = defaultPhoneInputIndex
    let emailInputIndex = defaultEmailInputIndex

    let formInputs = []
    switch (subscribeOrdering as string) {
        case websiteSubscribeOrderPhoneFirstVariation:
            formInputs = [defaultFormInputs[defaultPhoneInputIndex], defaultFormInputs[defaultEmailInputIndex]]
            phoneInputIndex = 0
            emailInputIndex = 1
            break
        case websiteSubscribeOrderEmailFirstVariation:
        default:
            formInputs = [defaultFormInputs[defaultEmailInputIndex], defaultFormInputs[defaultPhoneInputIndex]]
            phoneInputIndex = 1
            emailInputIndex = 0
            break
    }

    // define and object with {name: value} pair
    const [inputValues, setInputValues] = React.useState(() => {
        const values = {}

        formInputs?.map((input) => {
            if (input?.name) {
                values[input?.name] = ''
            }
        })

        return values
    })

    const showFullScreen = useFeature(websiteSubscribeFullScreenExperimentName).value ?? true

    const isMobileScreen = useMobileScreenDetection()

    // This is the second time we call the subscribe API
    const handleFormSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault()

        let lastInputIndex = phoneInputIndex

        if (subscribeOrdering === websiteSubscribeOrderPhoneFirstVariation) {
            lastInputIndex = emailInputIndex
        }

        const lastInput = formInputs?.[lastInputIndex]
        const phoneInput = formInputs?.[phoneInputIndex]

        const isLastInputValid = validateInput(lastInput as IFormInput)

        if (!isLastInputValid) {
            focusInput(lastInputIndex)
            setErrorInputIndex(lastInputIndex)
            return
        }

        const isPhoneInputValid = validateInput(phoneInput as IFormInput)

        try {
            setIsSubmittingForm(true)

            const response = await fetchWithTimeout(action, {
                method: 'POST',
                body: JSON.stringify({ ...inputValues, subscribeLocation: siteLocation }),
            })

            if (response.ok) {
                setFormSubmissionStatus('success')

                // Determine which subscribe came second
                let subscribeType = ''
                if (subscribeOrdering === websiteSubscribeOrderEmailFirstVariation) {
                    subscribeType = formInputs[phoneInputIndex].name
                } else if (subscribeOrdering === websiteSubscribeOrderPhoneFirstVariation) {
                    subscribeType = formInputs[emailInputIndex].name
                }

                track(subscribeEventName(subscribeType), {
                    subscribeLocation: siteLocation,
                    includesPhoneNumber: isPhoneInputValid,
                })
            } else {
                setFormSubmissionStatus('error')
            }
        } catch (e) {
            Sentry.captureException(e)
            setFormSubmissionStatus('error')
        } finally {
            setIsSubmittingForm(false)
        }
    }

    const validateInput = (input: IFormInput) => {
        if (input?.type === 'email') {
            const email = inputValues[input?.name]
            return validateEmail(email)
        } else if (input?.type === 'tel') {
            const phone = inputValues[input?.name]
            return validateMobilePhone(phone)
        }

        return true
    }

    const nextInput = async (currentIndex: number, input: IFormInput) => {
        const isValid = validateInput(input)

        if (isValid) {
            setActiveInputIndex(currentIndex + 1)
            setErrorInputIndex(-1)

            const value = inputValues[input?.name]

            try {
                // call api with current input
                // when navigating to next input
                const response = await fetch(action, {
                    method: 'POST',
                    body: JSON.stringify({ [input.name]: value, subscribeLocation: siteLocation }),
                })

                if (response.status === 200) {
                    identify(value)

                    track(subscribeEventName(input.name), {
                        subscribeLocation: siteLocation,
                        includesPhoneNumber: input.name.includes('phone'),
                    })
                }
            } catch (e) {
                Sentry.captureException(e)
            }
        } else {
            // focus when input fails validation
            // needed in cases where users focuses out
            // of input, eg: tab + enter, button click
            // especially helpful for mobile users as it
            // keeps the onscreen keyboard up which then
            // prevents layout shift
            focusInput(currentIndex)

            setErrorInputIndex(currentIndex)
        }
    }

    // could be form submission error
    // or input validation error
    let error

    if (formSubmissionStatus === 'error') {
        error = (
            <ContentfulRichText
                className="error-message"
                // @ts-ignore
                content={errorMessage?.json}
                renderNode={errorMessageRenderNode}
            />
        )
    } else if (errorInputIndex !== -1) {
        error = formInputs?.[errorInputIndex]?.failureMessage
    }

    // When a customer completes the first part of this form, they are identified in the system
    // That means they could have "identity" but we still want them to finish the form
    // and enter their phone number.
    const identitySetBeforeStart = Boolean(identity) && activeInputIndex === 0

    const isShowPopup = isOpen && !isShouldNotBeDisplayed && !identitySetBeforeStart

    return (
        <>
            <div className="absolute bottom-0 h-px" ref={markerElRef} />
            {isShowPopup && (
                <Modal
                    modalWrapperClassName={styles['signup-popup-overlay']}
                    className={`${styles['signup-popup']} ${
                        showFullScreen ? '!w-full h-full lg:!max-w-[1048px] lg:max-h-[610px]' : 'max-w-md'
                    }`}
                    radiusClassName="rounded-0"
                    closeModal={() => {
                        closePopup?.()
                        setHasBeenDisplayed?.(true)
                    }}
                    crossButtonClassName={`${styles['modal-close-button']} ${
                        showFullScreen ? styles['modal-close-button-big'] : ''
                    }`}
                >
                    <div
                        className={`p-0 flex flex-col bg-painFever-lighter ${
                            showFullScreen ? 'h-full lg:grid lg:grid-cols-[.65fr,1.5fr]' : ''
                        }`}
                    >
                        <div
                            className={`relative ${
                                showFullScreen
                                    ? 'h-0 flex-grow aspect-square lg:aspect-auto lg:flex-grow-0 lg:h-auto'
                                    : ''
                            }`}
                        >
                            {/* cabinet logo */}
                            <LogoWithTradeMark
                                white
                                className={`absolute top-5 w-full ${showFullScreen ? 'lg:hidden' : ''}`}
                            />

                            <picture>
                                {/* desktop image */}
                                {showFullScreen && (
                                    <>
                                        <source
                                            type="image/webp"
                                            media={`(min-width: ${lgBreakPoint}px)`}
                                            srcSet={`
                                        ${media?.url}?w=400&fm=webp 400w,
                                        ${media?.url}?w=800&fm=webp 800w,
                                    `}
                                            sizes="400px"
                                        />
                                        <source
                                            type="image/jpeg"
                                            media={`(min-width: ${lgBreakPoint}px)`}
                                            srcSet={`
                                        ${media?.url}?w=400&fm=jpg 400w,
                                        ${media?.url}?w=800&fm=jpg 800w,
                                    `}
                                            sizes="400px"
                                        />
                                    </>
                                )}
                                {/* mobile image */}
                                <source
                                    type="image/webp"
                                    srcSet={`
                                        ${mobileMedia?.url}?w=400&fm=webp 400w,
                                        ${mobileMedia?.url}?w=800&fm=webp 800w,
                                    `}
                                    sizes="400px"
                                />
                                <source
                                    type="image/jpeg"
                                    srcSet={`
                                        ${mobileMedia?.url}?w=400&fm=jpg 400w,
                                        ${mobileMedia?.url}?w=800&fm=jpg 800w,
                                    `}
                                    sizes="400px"
                                />

                                <img
                                    src={(mobileMedia?.url || media?.url) + '?w=400&fm=jpg'}
                                    height={mobileMedia?.height || media?.height}
                                    width={mobileMedia?.width || media?.width}
                                    alt={
                                        mobileMedia?.description ||
                                        mobileMedia?.title ||
                                        media?.description ||
                                        media?.title
                                    }
                                    className={`w-full object-cover ${showFullScreen ? 'h-full' : 'lg:max-h-96'}`}
                                />
                            </picture>
                        </div>
                        <div className="p-6 flex flex-col flex-grow-0 justify-between">
                            <div className="h-full">
                                {/* cabinet logo */}
                                <LogoWithTradeMark
                                    className={`w-full hidden ${showFullScreen ? 'lg:flex lg:-mt-2.5' : ''}`}
                                />

                                <div
                                    className={`${
                                        showFullScreen ? 'flex flex-col h-full justify-center items-center' : ''
                                    }`}
                                >
                                    {/* form or success message */}
                                    {formSubmissionStatus === 'success' ? (
                                        <ContentfulRichText
                                            className="success-message"
                                            // @ts-ignore
                                            content={successMessage?.json}
                                            renderNode={successMessageRenderNode}
                                            links={successMessage?.links}
                                            renderPicture={renderPicture}
                                        />
                                    ) : (
                                        <>
                                            <ContentfulRichText
                                                className=""
                                                // @ts-ignore
                                                content={header?.json}
                                                renderNode={
                                                    showFullScreen ? headerFullLayoutRenderNode : headerRenderNode
                                                }
                                            />

                                            <ContentfulRichText
                                                className={`${showFullScreen ? 'mt-5 lg:mt-8' : 'mt-2'} lg:max-w-md`}
                                                // @ts-ignore
                                                content={
                                                    isMobileScreen || !showFullScreen
                                                        ? subHeader?.json
                                                        : paragraph?.json
                                                }
                                                renderNode={
                                                    showFullScreen ? subHeaderFullLayoutRenderNode : subHeaderRenderNode
                                                }
                                            />
                                            <div
                                                className={`${showFullScreen ? 'mt-5 lg:mt-5 lg:max-w-sm' : 'mt-3.5'} ${
                                                    styles['flip-card']
                                                } ${activeInputIndex !== 0 ? 'flip' : ''}`}
                                            >
                                                {/* inputs */}
                                                {formInputs?.length > 0 && (
                                                    <form
                                                        ref={formRef}
                                                        onSubmit={handleFormSubmit}
                                                        className={`${styles['flip-card-inner']}`}
                                                    >
                                                        {formInputs?.map((input, index) => (
                                                            <div
                                                                className={`
                                                                flex
                                                                ${styles['face']}
                                                                ${
                                                                    index === 0
                                                                        ? styles['flip-card-front']
                                                                        : styles['flip-card-back']
                                                                }
                                                                ${showFullScreen ? 'lg:flex-col lg:space-y-3' : ''}
                                                            `}
                                                                key={input?.sys?.id}
                                                            >
                                                                <input
                                                                    type={input?.type}
                                                                    name={input?.name}
                                                                    // disable last input initially to fix chrome android bug
                                                                    // which does not trigger keyDown event when next button on
                                                                    // on-screen keyboard is clicked
                                                                    disabled={index > activeInputIndex}
                                                                    className={`
                                                                        px-6 py-2.5 text-base font-medium
                                                                        rounded-l-full grow
                                                                        border border-darkgray
                                                                        bg-transparent
                                                                        ${
                                                                            errorInputIndex === index
                                                                                ? 'border-coldFlu !ring-coldFlu'
                                                                                : ''
                                                                        }
                                                                        ${
                                                                            showFullScreen
                                                                                ? 'lg:rounded-full lg:text-xl lg:font-bold'
                                                                                : ''
                                                                        }
                                                                    `}
                                                                    placeholder={input?.placeholder}
                                                                    value={inputValues[input?.name]}
                                                                    onChange={(e) => {
                                                                        setInputValues({
                                                                            ...inputValues,
                                                                            [input?.name]: e.target.value,
                                                                        })
                                                                        setErrorInputIndex(-1)
                                                                    }}
                                                                    onKeyDown={
                                                                        index === formInputs?.length - 1
                                                                            ? undefined
                                                                            : (e) => {
                                                                                  // for every input except the last
                                                                                  // prevent form submission on enter
                                                                                  // key press, instead show next input
                                                                                  // note: e.key === 'Enter' needed for
                                                                                  // chrome/firefox android
                                                                                  if (
                                                                                      e.code === 'Enter' ||
                                                                                      e.key === 'Enter'
                                                                                  ) {
                                                                                      e.preventDefault()
                                                                                      nextInput(
                                                                                          index,
                                                                                          input as IFormInput,
                                                                                      )
                                                                                  }
                                                                              }
                                                                    }
                                                                />
                                                                <Button
                                                                    className={`px-3.5 py-2.5 text-xsm font-medium rounded-none rounded-r-full bg-black border-0 relative min-w-32 ${
                                                                        showFullScreen
                                                                            ? 'lg:rounded-full lg:py-2.5 lg:text-xl lg:font-bold'
                                                                            : ''
                                                                    } ${error && showFullScreen ? 'lg:!mt-6' : ''}`}
                                                                    type={
                                                                        index === formInputs?.length - 1
                                                                            ? 'submit'
                                                                            : 'button'
                                                                    }
                                                                    onClick={
                                                                        index === formInputs?.length - 1
                                                                            ? undefined
                                                                            : () =>
                                                                                  nextInput(index, input as IFormInput)
                                                                    }
                                                                    loading={isSubmittingForm}
                                                                >
                                                                    <div
                                                                        className={`absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 ${
                                                                            index === 0 ? '' : 'opacity-0'
                                                                        }`}
                                                                    >
                                                                        {showFullScreen && (
                                                                            <span className="hidden lg:block">
                                                                                {
                                                                                    globalUIResources[
                                                                                        'emailCapturePopupFirstInputButtonLabel'
                                                                                    ].value
                                                                                }
                                                                            </span>
                                                                        )}
                                                                        <ArrowRightIcon
                                                                            className={`${
                                                                                showFullScreen ? 'lg:hidden' : ''
                                                                            }`}
                                                                            stroke={colors.white}
                                                                            strokeWidth={2}
                                                                            height="12"
                                                                            width="12"
                                                                        />
                                                                    </div>
                                                                    <span
                                                                        className={`${index === 0 ? 'opacity-0' : ''}`}
                                                                    >
                                                                        {submitButtonText}
                                                                    </span>
                                                                </Button>
                                                            </div>
                                                        ))}
                                                    </form>
                                                )}
                                            </div>

                                            {error && <div className="text-coldFlu text-sm max-w-lg">{error}</div>}

                                            {/* legal disclaimer */}
                                            {activeInputIndex !== 0 && (
                                                <ContentfulRichText
                                                    className={`
                                                        relative max-w-md
                                                        top-2.5 ${
                                                            showFullScreen
                                                                ? error
                                                                    ? 'lg:top-15'
                                                                    : 'lg:top-[4.25rem]'
                                                                : ''
                                                        }
                                                    `}
                                                    // @ts-ignore
                                                    content={disclaimer?.json}
                                                    renderNode={disclaimerRenderNode}
                                                />
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>

                            {showFullScreen && (
                                <div>
                                    {/* line */}
                                    <hr className="hidden lg:block border-t-darkgray w-4/5 mt-8 mx-auto" />

                                    {brandIcons.length > 0 && (
                                        <div className="flex justify-center overflow-auto scrollbar-hidden -mx-6 mt-5 lg:mt-10 mb-4 lg:mb-2">
                                            <div className="flex">
                                                {brandIcons.map((icon) => {
                                                    const { url, title, height, width, description } = icon.media

                                                    if (!url) {
                                                        return null
                                                    }

                                                    return (
                                                        <picture
                                                            key={icon.key}
                                                            className="flex-shrink-0 ml-0 first:ml-0 px-3.5 lg:px-2"
                                                        >
                                                            {url.endsWith('.svg') && (
                                                                <source type="image/svg+xml" srcSet={url} />
                                                            )}
                                                            <source type="image/webp" srcSet={url + '?fm=webp'} />
                                                            <source type="image/jpeg" srcSet={url + '?fm=jpg'} />

                                                            <img
                                                                src={url}
                                                                alt={description || title}
                                                                height={height}
                                                                width={width}
                                                                className="h-9 lg:h-10 w-full"
                                                            />
                                                        </picture>
                                                    )
                                                })}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                    </div>
                </Modal>
            )}
        </>
    )
}
