import { useQuery } from '@apollo/client'
import React, { createContext, useContext, useEffect, useState, useMemo } from 'react'
import { useRouter } from 'next/router'
import { useAuth } from 'components/auth/auth'
import { useCustomerContext, NEW_CUSTOMER_KEY } from 'context/customer-context'
import { CLIENT_SIDE_USER_GRAPHQL_QUERY, fetchUserMembershipPortal, fetchUserMembershipStatus } from 'lib/api/users'
import { IUser } from 'interfaces/user'

interface IUserContext {
    user: IUser
    loading: boolean
}

/**
 * To note, our Auth context also has a "user" property; that is the user returned from the auth flow
 *
 * The UserContext, as defined here, is the source of truth for all non-auth0 related properties of a user
 * as defined in our database.
 */
const UserContext = createContext<IUserContext>(null)
interface IProps {
    children: React.ReactNode
}

const UserContextProvider = ({ children }: IProps): React.ReactElement => {
    const [userMembership, setUserMembership] = useState(null)
    const { user: authUser, isAuthenticated, loading: authLoading, idToken } = useAuth()
    const customer = useCustomerContext()
    const { query } = useRouter()

    const { data: userData, loading: loadingUser } = useQuery(CLIENT_SIDE_USER_GRAPHQL_QUERY, {
        skip: !isAuthenticated,
    })

    const user = userData?.user?.[0]

    // Identify customer if email passed in as a query param
    useEffect(() => {
        if (query.email) {
            const email = Array.isArray(query.email) ? query.email[0] : query.email
            customer.identify(email)
        }
    }, [query, customer])

    // Get the customer's membership details
    useEffect(() => {
        if (authUser && user) {
            const fetchMembership = async () => {
                const { data: statusData, error: statusError } = await fetchUserMembershipStatus({
                    email: user.email,
                })
                if (!statusError) {
                    setUserMembership({ membership: { ...statusData } })
                    // Grab the link to their Stripe portal
                    const { data: portalData, error: portalError } = await fetchUserMembershipPortal(idToken)
                    if (!portalError) {
                        setUserMembership({ membership: { ...statusData, ...portalData } })
                    }
                }
            }
            fetchMembership()
        }
    }, [authUser, user, idToken])

    // If we know auth has finished loading, identify user in heap
    useEffect(() => {
        if (customer && !authLoading && !customer.loading && isAuthenticated && authUser?.email) {
            customer.identify(authUser.email)
            // Assuming if they have an account they're a returning customer
            customer.clearEventProperties()
            customer.addEventProperties({ [NEW_CUSTOMER_KEY]: false })
        }
    }, [customer, customer?.loading, authLoading, authUser, isAuthenticated])

    const userValue = useMemo(() => {
        const updatedUser = {
            ...user, // data from our db
            ...userMembership,
        }
        return { user: updatedUser, loading: authLoading || loadingUser }
    }, [user, authLoading, loadingUser, userMembership])

    return <UserContext.Provider value={userValue}>{children}</UserContext.Provider>
}

export const useUserContext = (): IUserContext => {
    const accountContext = useContext(UserContext)

    if (typeof UserContext === 'undefined') {
        throw new Error('You cannot use UserContext outside UserContextProvider')
    }

    return accountContext
}

export default UserContextProvider
