import * as React from 'react'
import { CheckboxWithOutline } from './checkbox-with-outline'
import { CheckboxWithLabel } from './checkbox-with-label'
import { CheckboxSimple } from './checkbox-simple'

export interface IPropsBase {
    label: string
    checkMarkClassName?: string
    checkedTickMarkColor?: string
}

export interface IPropsSimpleOrOutline extends IPropsBase {
    variant: 'simple' | 'outline'
    labelClassName?: string
    checkedBackgroundColor?: string
}

export interface IPropsLabel extends IPropsBase {
    variant: 'label'
    labelClassName: string
    checkedBackgroundColor: string
}

export type IProps = IPropsSimpleOrOutline | IPropsLabel

export function Checkbox(props: IProps & React.ComponentProps<'input'>) {
    switch (props.variant) {
        case 'outline':
            return <CheckboxWithOutline {...props} />
        case 'label':
            return <CheckboxWithLabel {...props} />
        case 'simple':
        default:
            return <CheckboxSimple {...props} />
    }
}
