import { colors } from 'theme.cjs'

const CartIcon = ({
    height = '16',
    width = '16',
    fill = colors.mineshaft,
    className = '',
    stroke = colors.mineshaft,
    badge = undefined,
    strokeWidth = 2,
} = {}): React.ReactElement => (
    <div className={`relative ${className}`}>
        <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} fill="none">
            <path d="M0 11a5 5 0 0 0 5 5h6a5 5 0 0 0 5-5V4.364H0V11Z" fill={fill} />
            <path
                d="M5 3a2 2 0 0 1 2-2h2a2 2 0 0 1 2 2v1.364A2.636 2.636 0 0 1 8.364 7h-.728A2.636 2.636 0 0 1 5 4.364V3Z"
                stroke={stroke}
                strokeWidth={strokeWidth}
            />
        </svg>
        {Boolean(badge) && (
            <div className="text-white grid text-xsm -font-bold leading-120% text-center absolute -bottom-0.5 w-full">
                {badge}
            </div>
        )}
    </div>
)

export default CartIcon
