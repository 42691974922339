import { APP_ENV } from 'enum/app-env'

export const SENTRY_DSN =
    process.env.SENTRY_DSN ||
    process.env.NEXT_PUBLIC_SENTRY_DSN ||
    'https://eae0597b082b451096b94f2bc74a4d41@o1189171.ingest.sentry.io/6309438'
export const EXCLUDED_ENVIRONMENTS: string[] = [APP_ENV.LOCAL, APP_ENV.TESTING]
export const FULL_CAPTURE_SAMPLE_RATE = 1
export const HIGH_PRIORITY_SAMPLE_RATE = 0.5
export const MED_PRIORITY_SAMPLE_RATE = 0.35
export const LOW_PRIORITY_SAMPLE_RATE = 0.15
