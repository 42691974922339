import React, { useState, useEffect, useContext } from 'react'
import createAuth0Client from '@auth0/auth0-spa-js'
import { useRouter } from 'next/router'
import EmailVerification from './email-verficiation'
import EmailVerified from './email-verified'
import globalManifest from 'data/global-manifest.json'
import { APP_ENV } from 'enum/app-env'
import * as Sentry from '@sentry/nextjs'
import Modal from 'components/modal/modal'
import ContentfulRichText from 'components/rich-text'
import { BLOCKS } from '@contentful/rich-text-types'

const { commonCopy } = globalManifest

export const cabinetJWTNamespace = 'https://cabinethealth.com/jwt/claims'

const popupMessageRenderNode = {
    // eslint-disable-next-line react/display-name
    [BLOCKS.PARAGRAPH]: (_node, children) => <p>{children}</p>,
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const DEFAULT_REDIRECT_CALLBACK = (appState) =>
    window.history.replaceState({}, document.title, window.location.pathname)

const EMAIL_UNVERIFIED_STATUS_COODE = '60001'
const EMAIL_VERIFIED_STATUS = 'success'
const EMAIL_VERIFIED_MSG = 'message=Your%20email%20was%20verified'

export const AuthContext = React.createContext()
export const useAuth = () => useContext(AuthContext)
export const AuthContextProvider = ({ children, onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, ...initOptions }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [user, setUser] = useState()
    const [auth0Client, setAuth0] = useState()
    const [loading, setLoading] = useState(true)
    const [errorAction, setErrorAction] = useState() // 'login' | 'signup'
    const [idToken, setIdToken] = useState('')

    const router = useRouter()
    const { asPath } = router

    useEffect(() => {
        const initAuth0 = async () => {
            try {
                // auth0 recommends storing tokens in browser memory over storage
                // for more secure way for storage and transmission
                // but our setup in staging breaks does not work when a user has
                // blocked third party cookies, since this is staging and most likely
                // there won't be any sensitive data we're using local strorage for the
                // staging environment
                // further read: https://auth0.com/docs/secure/security-guidance/data-security/token-storage
                // ** let's be cautious that we don't enable this for production
                const cacheLocation =
                    window.Cypress || process.env.APP_ENV === APP_ENV.STAGING ? 'localstorage' : 'memory'
                const auth0FromHook = await createAuth0Client({ ...initOptions, cacheLocation })
                setAuth0(auth0FromHook)

                if (window.location.search.includes('code=') && !window.location.search.includes('shortcode=')) {
                    const { appState } = await auth0FromHook.handleRedirectCallback()
                    onRedirectCallback(appState)
                } else {
                    await auth0FromHook.checkSession()
                }

                const isAuthenticated = await auth0FromHook.isAuthenticated()

                if (isAuthenticated) {
                    const user = await auth0FromHook.getUser()
                    setUser(user)
                    const idTokenClaims = await auth0FromHook.getIdTokenClaims()
                    setIdToken(idTokenClaims?.__raw)
                }

                setIsAuthenticated(isAuthenticated)
            } catch (e) {
                Sentry.captureException(e)
            } finally {
                setLoading(false)
            }
        }
        initAuth0()
        // eslint-disable-next-line
    }, [])

    const logout = async (...p) => {
        setLoading(true)
        await router.push('/')
        await auth0Client.logout(...p)
        setLoading(false)
        setUser()
        setIsAuthenticated(false)
        window.heap.resetIdentity()
    }

    const closeErrorPopup = React.useCallback(() => {
        setErrorAction(null)
    }, [])

    const loginWithRedirect = async (...p) => {
        if (!navigator.cookieEnabled) {
            setErrorAction('login')
            return
        }

        setLoading(true)
        auth0Client.loginWithRedirect(...p)
    }

    const signupWithRedirect = async (...p) => {
        if (!navigator.cookieEnabled) {
            setErrorAction('signup')
            return
        }

        loginWithRedirect(...p)
    }

    const [ui, setUI] = React.useState(children)

    React.useEffect(() => {
        /**
         * If we need email verification or if the user just clicked on the email verification link,
         * show the appropriate login modal screen
         */
        const needsEmailVerification =
            asPath?.includes(EMAIL_UNVERIFIED_STATUS_COODE) && asPath?.includes('error_description=')
        const fromEmailVerification = asPath?.includes(EMAIL_VERIFIED_STATUS) && asPath?.includes(EMAIL_VERIFIED_MSG)

        if (needsEmailVerification) {
            setUI(<EmailVerification />)
            return
        }

        if (fromEmailVerification) {
            setUI(<EmailVerified />)
            return
        }

        // const forceStagingLogin = features?.['staging-force-login']?.defaultValue ?? true

        // if (process.env.APP_ENV === APP_ENV.LOCAL && !isAuthenticated) {
        // if (isFetchingFeatures || forceStagingLogin) {
        // show = 'login'
        /* setUI(
            <BlankLayout>
                <Login
                    pageTitle={uiResources?.loginToStaging?.value}
                    loginMessage={commonCopy?.['loginMessageStaging']?.value?.json}
                />
            </BlankLayout>,
        ) */
        // }
        // }

        setUI(children)
    }, [asPath, children])

    return (
        <AuthContext.Provider
            value={{
                idToken,
                isAuthenticated,
                user,
                loading,
                logout,
                loginWithRedirect,
                signupWithRedirect,
                getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
                getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
                getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
            }}
        >
            {ui}

            {errorAction && (
                <Modal closeModal={closeErrorPopup}>
                    <div className="p-5">
                        {
                            <ContentfulRichText
                                content={
                                    commonCopy[
                                        errorAction === 'login'
                                            ? 'enableCookieForLoginMessage'
                                            : 'enableCookieForSignupMessage'
                                    ].value.json
                                }
                                renderNode={popupMessageRenderNode}
                            />
                        }
                    </div>
                </Modal>
            )}
        </AuthContext.Provider>
    )
}
